import React, { FC, useState } from 'react';
import { LayoutHeader } from './components/LayoutHeader';
import LayoutNavbar from './components/LayoutNavbar';
import { GLOBAL_CONSTANTS } from '../../core/constants/constants';
import styles from './Layout.module.scss';
import { Outlet } from 'react-router-dom';

const Layout: FC = () => {
  const [navbarOpened, setNavbarOpened] = useState(false);

  return (
    <div className="container">
      <LayoutHeader />
      <div className={styles.content}>
       
        <LayoutNavbar setNavbarOpened={setNavbarOpened} />
        <div
          className={styles.outletWrapper}
          style={{ maxWidth: `calc(inherit - ${navbarOpened ? '207px' : '62px'})` }}
        >
          <div style={{ width: navbarOpened ? '207px' : '62px', flexShrink: 0 }}></div>
          <div className={styles.outlet}>
            <Outlet />
          </div>
        </div>
      </div>
      
      <div style={{display:'flex', justifyContent:'center', alignItems:'center', color:'#888'}}>
       <p>(c) 2024-2025, ООО &ldquo;Акварель&rdquo;. Версия {GLOBAL_CONSTANTS.BUILD_VER}</p>
      </div>        
    </div>
  );
};

export default Layout;
