import React, { FC, useEffect, useState } from 'react';
import clsx from 'clsx';
import { Loader } from '@mantine/core';

import { PartnerShortInfo } from './PartnersTable.types';
import { CellWithSorting } from 'components/CellWithSorting';
import { Modal } from 'components/Modal';
import { PartnerEdit } from 'pages/Partners/PartnerEdit';
import { PrivatePerson } from 'pages/Partners/components/PartnerFormPrivatePerson/PartnerFormPrivatePerson.types';
import { LegalPerson } from 'pages/Partners/components/PartnerFormLegalPerson/PartnerFormLegalPerson.types';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { partnersColumns, partnersJuricalColumns } from './data';
import { setParamsPage, setParamsSort } from 'store/entities/partners/partnersSlice';
import { getPartner } from 'store/action-creators/partners';
import { Pagination } from '@mantine/core';
import styles from './PartnersTable.module.scss';
import { PartnersDataSorting } from '../../../services/partners/partnerApi.service';
import { OrderProps } from 'pages/Orders/Order.types';
import { OrderEdit } from 'pages/Orders/OrderEdit';
import { getStatusColor, getStatusLK } from 'core/utils/getStatus';

export const PartnersTable: FC = () => {
  const dispatch = useAppDispatch();
  const {
    partners,
    countPartners,
    params,
    isLoading,
    isSuccess,
    currentPrivatePersone,
    currentLegalPersone,
    preSavePrivatePerson,
    preSaveLegalPerson,
  } = useAppSelector((state) => state.partners);
  const { preSaveOrder, isOdrderModal } = useAppSelector((state) => state.orders);
  const [orderToEdit, setOrderToEdit] = useState<OrderProps | null>(null);
  const [openedOrder, setOpenedOrder] = useState(false);
  const [partnersList, setPartnersList] = useState<PartnerShortInfo[]>([]);
  const [modal, setModal] = useState(false);
  const [countPages, setCountPages] = useState<number>(1);
  useEffect(() => {
    if (preSaveOrder && isOdrderModal) {
      setOpenedOrder(true);
      setOrderToEdit(preSaveOrder);
    }
  }, [preSaveOrder]);
  const handleClickRow = (item: PartnerShortInfo) => {
    setModal(true);
    dispatch(getPartner({ id: item.id.toString() }));
  };

  useEffect(() => {
    if (partners) {
      const secondNameArray = partners.filter(
        (el) =>
          el.contact === null &&
          el.secondName &&
          params.search &&
          el.secondName.toLowerCase().includes(params.search.toLowerCase())
      );
      const firstNameArray = partners.filter(
        (el) =>
          el.contact === null &&
          el.firstName &&
          params.search &&
          el.firstName.toLowerCase().includes(params.search.toLowerCase())
      );
      const midleNameArray = partners.filter(
        (el) =>
          el.contact === null &&
          el.middleName &&
          params.search &&
          el.middleName.toLowerCase().includes(params.search.toLowerCase())
      );
      if (secondNameArray.length === 0 && firstNameArray.length === 0 && midleNameArray.length === 0) {
        setPartnersList(partners);
        return;
      }
      setPartnersList(
        [...secondNameArray, ...firstNameArray, ...midleNameArray].filter((el, i, arr) => arr.indexOf(el) === i)
      );
    }
  }, [partners]);

  const handleSort = (key: keyof PartnersDataSorting, sort: 'desc' | 'asc') => {
    const data: Partial<PartnersDataSorting> = {};
    data[key] = sort === 'asc';
    dispatch(setParamsSort(data));
  };

  // высчитываем кол-во страниц в зависимости от кол-ва элементов и числа элементов на странице
  useEffect(() => {
    setCountPages(Math.ceil(countPartners / params.itemsPerPage));
  }, [countPartners, params.itemsPerPage]);

  // следим за скроллом. если достигаем конца страницы устанавливаем его в true
  // useEffect(() => {
  //   const handleScroll = (e: any) => {
  //     const scrollHeight = e.target.documentElement.scrollHeight;
  //     const currentHeight = e.target.documentElement.scrollTop + window.innerHeight;
  //     setScroll(false);
  //     if (currentHeight + 10 >= scrollHeight && params.currentPage <= countPages) {
  //       setScroll(true);
  //     }
  //   };
  //   window.addEventListener('scroll', handleScroll);
  //   return () => window.removeEventListener('scroll', handleScroll);
  // }, [params.currentPage]);

  // когда достигли конца страницы, увеличиваем счетчик страниц на 1
  // useEffect(() => {
  //   if (scroll && params.currentPage < countPages && !params.search) {
  //     dispatch(setParamsPage(params.currentPage + 1));
  //   }
  // }, [scroll]);

  const closePartnerModal = () => {
    console.log('close');
    // if (currentPrivatePersone) {
    //   let dataToStore: AddPartnerData = {
    //     firstName: preSavePrivatePerson.commonInfo.firstName,
    //     middleName: preSavePrivatePerson.commonInfo.patronymic,
    //     secondName: preSavePrivatePerson.commonInfo.lastName,
    //     fullName: `${preSavePrivatePerson.commonInfo.lastName} ${preSavePrivatePerson.commonInfo.firstName} ${preSavePrivatePerson.commonInfo.patronymic}`,
    //     subject: SUBJECT.PRIVATE_PERSON,
    //     gender: preSavePrivatePerson.commonInfo.gender,
    //     birthday: preSavePrivatePerson.commonInfo.birthDate
    //       ? getTimezonelessDate(preSavePrivatePerson.commonInfo.birthDate)
    //       : null,
    //     phone: preSavePrivatePerson.commonInfo.phone,
    //     email: preSavePrivatePerson.commonInfo.email,
    //     note: preSavePrivatePerson.commonInfo.comments,
    //   };

    //   if (preSavePrivatePerson.documents.passport?.number !== '') {
    //     dataToStore = {
    //       ...dataToStore,
    //       contactPassport: {
    //         id: preSavePrivatePerson.documents.passport?.id,
    //         code: preSavePrivatePerson.documents.passport?.number,
    //         registeredDate: preSavePrivatePerson.documents.passport?.dateOfIssue
    //           ? getTimezonelessDate(preSavePrivatePerson.documents.passport?.dateOfIssue)
    //           : null,
    //         actualBefore: preSavePrivatePerson.documents.passport?.validUntil
    //           ? getTimezonelessDate(preSavePrivatePerson.documents.passport?.validUntil)
    //           : null,
    //         unitCode: preSavePrivatePerson.documents.passport?.divisionCode,
    //         unitName: preSavePrivatePerson.documents.passport?.issuedByWhom,
    //         birthPlace: preSavePrivatePerson.documents.passport?.placeOfBirth,
    //         registrationPlace: preSavePrivatePerson.documents.passport?.placeOfStateRegistration,
    //       },
    //     };
    //   } else {
    //     dataToStore = {
    //       ...dataToStore,
    //       contactPassport: null,
    //     };
    //   }
    //   if (preSavePrivatePerson?.documents.interPassport?.number !== '') {
    //     dataToStore = {
    //       ...dataToStore,
    //       contactForeignPassport: {
    //         id: preSavePrivatePerson.documents.interPassport?.id,
    //         firstName: preSavePrivatePerson?.documents.interPassport?.firstName,
    //         lastName: preSavePrivatePerson?.documents.interPassport?.lastName,
    //         code: preSavePrivatePerson?.documents.interPassport?.number,
    //         registeredDate: preSavePrivatePerson?.documents.interPassport?.dateOfIssue
    //           ? getTimezonelessDate(preSavePrivatePerson?.documents.interPassport?.dateOfIssue)
    //           : null,
    //         actualBefore: preSavePrivatePerson?.documents.interPassport?.validUntil
    //           ? getTimezonelessDate(preSavePrivatePerson?.documents.interPassport?.validUntil)
    //           : null,
    //         unitCode: preSavePrivatePerson?.documents.interPassport?.divisionCode,
    //         unitName: preSavePrivatePerson?.documents.interPassport?.issuedByWhom,
    //         birthPlace: preSavePrivatePerson?.documents.interPassport?.placeOfBirth,
    //         registrationPlace: preSavePrivatePerson?.documents.interPassport?.placeOfStateRegistration,
    //       },
    //     };
    //   } else {
    //     dataToStore = {
    //       ...dataToStore,
    //       contactForeignPassport: null,
    //     };
    //   }

    //   if (preSavePrivatePerson?.documents.birthCertificate?.number !== '') {
    //     dataToStore = {
    //       ...dataToStore,
    //       contactBirthCertificate: {
    //         id: preSavePrivatePerson.documents.birthCertificate?.id,
    //         code: preSavePrivatePerson?.documents.birthCertificate?.number,
    //         registeredDate: preSavePrivatePerson?.documents.birthCertificate?.dateOfIssue
    //           ? getTimezonelessDate(preSavePrivatePerson?.documents.birthCertificate?.dateOfIssue)
    //           : null,
    //         fatherFullName: preSavePrivatePerson?.documents.birthCertificate?.father,
    //         motherFullName: preSavePrivatePerson?.documents.birthCertificate?.mother,
    //         registrationPlace: preSavePrivatePerson?.documents.birthCertificate?.placeOfStateRegistration,
    //       },
    //     };
    //   } else {
    //     dataToStore = {
    //       ...dataToStore,
    //       contactBirthCertificate: null,
    //     };
    //   }

    //   if (preSavePrivatePerson?.documents.foreignDocument?.number !== '') {
    //     dataToStore = {
    //       ...dataToStore,
    //       contactForeignDocument: {
    //         id: preSavePrivatePerson.documents.foreignDocument?.id,
    //         firstName: preSavePrivatePerson?.documents.foreignDocument?.firstName,
    //         lastName: preSavePrivatePerson?.documents.foreignDocument?.lastName,
    //         code: preSavePrivatePerson?.documents.foreignDocument?.number,
    //         nationality: preSavePrivatePerson?.documents.foreignDocument?.citizenship,
    //         registeredDate: preSavePrivatePerson?.documents.foreignDocument?.dateOfIssue
    //           ? getTimezonelessDate(preSavePrivatePerson?.documents.foreignDocument?.dateOfIssue)
    //           : null,
    //         registrationPlace: preSavePrivatePerson?.documents.foreignDocument?.placeOfStateRegistration,
    //       },
    //     };
    //   } else {
    //     dataToStore = {
    //       ...dataToStore,
    //       contactForeignDocument: null,
    //     };
    //   }
    //   if (preSavePrivatePerson.documents.mainDocument !== '') {
    //     dataToStore = {
    //       ...dataToStore,
    //       main_document: preSavePrivatePerson.documents.mainDocument,
    //     };
    //   } else {
    //     dataToStore = {
    //       ...dataToStore,
    //       main_document: null,
    //     };
    //   }

    //   dispatch(editPartner({ id: currentPrivatePersone.id?.toString() as string, data: { ...dataToStore } }));
    // }

    // if (currentLegalPersone) {
    //   const dataToStore: AddPartnerData = {
    //     subject: SUBJECT.LEGAL_PERSON,
    //     type: preSaveLegalPerson.type?.map((type) => type['@id']),
    //     company: preSaveLegalPerson.commonInfo.name,
    //     fullName: preSaveLegalPerson.commonInfo.name,
    //     contactFullName: preSaveLegalPerson.commonInfo.contactPerson.name,
    //     phone: preSaveLegalPerson.commonInfo.contactPerson.phone,
    //     email: preSaveLegalPerson.commonInfo.contactPerson.email,
    //     note: preSaveLegalPerson.commonInfo.comments,
    //     requisites: preSaveLegalPerson.requisites.map((el) => {
    //       return {
    //         id: el.id,
    //         juriName: el.legalName,
    //         inn: el.inn,
    //         kpp: el.kpp,
    //         ogrn: el.ogrn,
    //         juriAddress: el.address?.legal,
    //         address: el.address?.real,
    //         managerFullName: el.responsiblePerson.name || '',
    //         registrationDocument: el.responsiblePerson.permission,
    //         bik: el.bik,
    //         corrAccount: el.correspondentAccount,
    //         payAccount: el.settlementAccount,
    //       };
    //     }),
    //   };
    //   dispatch(editPartner({ id: currentLegalPersone.id?.toString() as string, data: { ...dataToStore } }));
    // }
  };

  return (
    <>
      {isLoading === 'GET_PARTNERS' && (
        <Loader color={'#70a4cb'} variant={'oval'} className={styles.loader} size={'lg'} />
      )}
      {partnersList.length > 0 && isLoading !== 'GET_PARTNERS' && (
        <div className={styles.tableWrapper}>
          <table className={styles.table}>
            <thead>
              {params.subject.nameForBack === 'juridical' ? (
                <tr>
                  {partnersJuricalColumns.map((column) => {
                    return (
                      <th key={column.id} className={clsx(styles.column, styles[`column_${column.id}`])}>
                        {column.sortingId ? (
                          <CellWithSorting
                            column={{ ...column, id: column.sortingId }}
                            setColumnSort={(value) => {
                              handleSort(column.sortingId as keyof PartnersDataSorting, value);
                            }}
                            sorting={params.sort[column.sortingId] || false}
                          />
                        ) : (
                          <span>{column.name}</span>
                        )}
                      </th>
                    );
                  })}
                </tr>
              ) : (
                <tr>
                  {partnersColumns.map((column) => {
                    return (
                      <th key={column.id} className={clsx(styles.column, styles[`column_${column.id}`])}>
                        {column.sortingId ? (
                          <CellWithSorting
                            column={{ ...column, id: column.sortingId }}
                            setColumnSort={(value) => {
                              handleSort(column.sortingId as keyof PartnersDataSorting, value);
                            }}
                            sorting={params.sort[column.sortingId] || false}
                          />
                        ) : (
                          <span>{column.name}</span>
                        )}
                      </th>
                    );
                  })}
                </tr>
              )}
            </thead>
            <tbody>
              {partnersList.map((element) => (
                <tr
                  key={element.id}
                  className={clsx(
                    styles.row,
                    element.registration_status === 'waiting_for_approve' && styles.greenColor
                  )}
                  onClick={() => handleClickRow(element)}
                >
                  <td className={clsx(styles.cell, styles.phone)}>{element.id}</td>
                  <td className={clsx(styles.cell, styles.partner)}>
                    {element.name}
                    <div className={styles.type}>{element.type}</div>
                  </td>
                  <td className={clsx(styles.cell, styles.phone)}>{element.phone}</td>
                  <td className={clsx(styles.cell, styles.email)}>{element.company_email || element.email}</td>
                  {params.subject.nameForBack === 'juridical' && (
                    <td
                      className={clsx(styles.cell, styles.email)}
                      style={{ color: getStatusColor(String(element.registration_status)) }}
                    >
                      {getStatusLK(String(element.registration_status))}
                    </td>
                  )}
                  <td className={clsx(styles.cell, styles.comment)}>{element.comment}</td>
                </tr>
              ))}
            </tbody>
          </table>
          {partnersList.length > 0 && (
            <div className={styles.tableFooter}>
              <div className={styles.count}>Всего записей — {countPartners}</div>

              <Pagination
                total={countPages}
                value={params.currentPage}
                onChange={(val) => dispatch(setParamsPage(val))}
              />
            </div>
          )}
        </div>
      )}
      {partnersList.length === 0 && isSuccess === 'GET_PARTNERS' && (
        <span className={styles.notFound}>Ничего не найдено</span>
      )}
      {modal && (
        <Modal opened={modal} setOpened={setModal} saveDataBeforeClose={closePartnerModal}>
          {isLoading === 'GET_PARTNER' && (
            <Loader color={'#70a4cb'} variant={'oval'} className={styles.loaderPartner} size={'lg'} />
          )}
          {currentPrivatePersone && <PartnerEdit partner={modal ? (currentPrivatePersone as PrivatePerson) : null} />}
          {currentLegalPersone && <PartnerEdit partner={modal ? (currentLegalPersone as LegalPerson) : null} />}
        </Modal>
      )}
      {openedOrder && isOdrderModal && (
        <Modal opened={isOdrderModal} setOpened={setOpenedOrder}>
          {orderToEdit && <OrderEdit order={orderToEdit} />}
        </Modal>
      )}
    </>
  );
};
