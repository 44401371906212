import { listOfOrderStatuses, listOfPaymentStatuses } from 'core/constants/lists';
import { BasicInfo } from 'pages/Orders/Order.types';
import { PartnerShortResponse } from 'services/partners/PartnerResponse.model';

export type BasicInfoTabProps = {
  basicInfo?: BasicInfo;
  setDirty: () => void;
  isCanEdit: boolean;
};

export const statusesOrder: string[] = listOfOrderStatuses;
export const statusesPayment: string[] = listOfPaymentStatuses;
export const propers = [
  'id',
  'first_name',
  'middle_name',
  'second_name',
  'full_name',
  'company',
  'contacts_array',
  'contact_full_name',
  'email',
  'phone',
  'birthday',
  'subject',
];
export type PartnersFromSearch = {
  id: number;
  name: string;
  firstName: string;
  secondName: string;
  middleName: string;
  phone?: string;
  email?: string;
  contact?: string;
  contact_array: { id: number; name: string; email: string; phone: string }[];
  birthday?: string | null;
  subject: string;
};

export const getPartnerObj = (partner: PartnerShortResponse) => {
  return {
    id: partner.id,
    name: partner.full_name,
    firstName: partner.first_name,
    secondName: partner.second_name,
    middleName: partner.middle_name,
    contact: partner.contact_full_name,
    contact_array: partner.contacts_array
      ? [
          { id: 0, name: partner.contact_full_name, email: partner.email, phone: partner.phone },
          ...partner.contacts_array.map((el, idx) => {
            return { id: idx === 0 ? 1 : idx + 1, name: el.name, email: el.email, phone: el.phone };
          }),
        ]
      : partner.contact_full_name
      ? [{ id: 0, name: partner.contact_full_name, email: partner.email, phone: partner.phone }]
      : [],
    phone: partner.phone,
    email: partner.email,
    birthday: partner.birthday,
    subject: partner.subject,
  };
};

export const searchPartnersUpgrate = (
  patnersList: PartnersFromSearch[],
  searchVal: string,
  setPatnersList: (value: React.SetStateAction<PartnersFromSearch[]>) => void
) => {
  if (patnersList.length > 0 && searchVal != '') {
    const secondNameArray = patnersList.filter(
      (el) => el.contact === null && el.secondName && el.secondName.toLowerCase().includes(searchVal.toLowerCase())
    );
    const firstNameArray = patnersList.filter(
      (el) => el.contact === null && el.firstName && el.firstName.toLowerCase().includes(searchVal.toLowerCase())
    );
    const midleNameArray = patnersList.filter(
      (el) => el.contact === null && el.middleName && el.middleName.toLowerCase().includes(searchVal.toLowerCase())
    );
    if (secondNameArray.length === 0 && firstNameArray.length === 0 && midleNameArray.length === 0) {
      setPatnersList(patnersList);
      return;
    }
    setPatnersList(
      [...secondNameArray, ...firstNameArray, ...midleNameArray].filter((el, i, arr) => arr.indexOf(el) === i)
    );
  }
};
