import { OrderAction, OrderProps } from '../../pages/Orders/Order.types';
import { IOrderConsultation, Order, OrderTour } from './orderApi.service';
import { OrderActionResponse } from '../orderActions/OrderActinResponse.model';
import { OrderActionsStatus } from '../../core/constants/enums';
import axios from 'axios';
import { getStatus } from '../../core/utils/getStatus';
import { TourShortInfo } from '../../pages/Tours/Tours.types';
import moment from 'moment/moment';
import { listOfIdsTypePlacements, nightCountsList } from 'core/constants/lists';
import { getDate, getTime } from 'core/utils/getDate';
import { getPaymentMethodEnToRu, getPaymentTypeEnToRu } from 'core/utils/getPaymentMethod';
import { orderNotificationIn } from './order.factory';

export const srcToFile = async (src: string, fileName: string) => {
  const response = await axios.get(src, {
    responseType: 'blob',
  });
  const mimeType = response.headers['content-type'];
  return new File([response.data], fileName, { type: mimeType });
};

export const getName = (obj: { first_name?: string | null; last_name?: string | null; middle_name?: string | null }) =>
  [obj.first_name, obj.middle_name, obj.last_name].filter((i) => i).join(' ');

const getTour = (obj: OrderTour): TourShortInfo => {
  return {
    id: obj.id,
    title: obj.name,
  };
};

export const getAction = (obj: OrderActionResponse): OrderAction => {
  return {
    id: obj.id,
    user: getName(obj.manager),
    date: new Date(obj.created_at),
    type: OrderActionsStatus.NOTE,
    description: obj.text,
    filePaths: obj.attachments ? (obj.attachments.filter((i) => i.length).length !== 0 ? obj.attachments : []) : [],
  };
};

export const orderResponseAdapter = (model: Order): OrderProps => {
  return {
    '@id': model['@id'],
    id: Number(model.id),
    isShared: model.isShared,
    status: model.status.value,
    site_request: model.site_request,
    order_consultation: model.order_consultation as IOrderConsultation,
    chat_message: model.chat_message,
    chat: model.chat,
    countUnread: model.countUnread,
    basicInfo: {
      contactList: model.contact.contacts_array
        ? [
          {
            id: 0,
            name: String(model.contact.contact_full_name),
            email: model.contact.email,
            phone: model.contact.phone,
          },
          ...model.contact.contacts_array.map((el, idx) => {
            return { id: idx + 2, name: el.name, email: el.email, phone: el.phone };
          }),
        ].filter((el) => el.name !== 'null')
        : model.contact.contact_full_name
          ? [
            { id: 0, name: model.contact.contact_full_name, email: model.contact.email, phone: model.contact.phone },
          ].filter((el) => el.name !== 'null')
          : [],
      partner: {
        id: model.contact.id,
        name: model.contact.full_name ? model.contact.full_name : '',
      },
      email: model.contact.subject === 'juridical' ? model.contact_person_email || model.contact.email : model.contact.email,
      phone: model.contact.subject === 'juridical' ? model.contact_person_phone || model.contact.phone : model.contact.phone,
      contactPerson: model.contact_person_full_name ? model.contact_person_full_name : '',
      requisites: model.contact.requisites,
      manager:
        model.manager && model.manager.id
          ? {
            id: model.manager.id,
            name: getName(model.manager),
          }
          : null,
      refundAmount: model.return_summ ? Number(model.return_summ) : 0,
      reminders: model.order_notifications ? model.order_notifications.map(orderNotificationIn) : [],
      orderActions: model.order_actions ? model.order_actions.map(getAction) : [],
      status: getStatus(model.status.value),
      payed_status: getStatus(model.payed_status),
    },
    tourInfo: {
      contract_date: model.contract_date ? new Date(model.contract_date) : null,
      contract_number: model.contract_number,
      contract_sum: model.contract_sum,
      pass_placements_array:
        model.pass_placements_array &&
        model.pass_placements_array.map((el) => {
          return {
            country: el.country,
            city: el.city,
            region: el.region,
            hotel: el.hotel,
            place_type: el.place_type,
            period:
              el.period_start && el.period_end ? [new Date(el.period_start), new Date(el.period_end)] : [null, null],
            tourists:
              model.tourists && model.tourists.length > 0
                ? model.tourists
                  .filter((item) => el.tourists.includes(String(item['@id'])))
                  .map(({ id, full_name, ['@id']: string, birthday, phone, contact_passport, ...other }) =>
                    birthday
                      ? phone
                        ? contact_passport && contact_passport.code
                          ? `${full_name} / ${getDate(new Date(birthday))} / ${contact_passport.code} / ${phone}`
                          : `${full_name} / ${getDate(new Date(birthday))} / ${phone}`
                        : `${full_name} / ${getDate(new Date(birthday))}`
                      : String(full_name)
                  )
                : [],
          };
        }),
      pass_tour_dates_array: model.pass_tour_dates_array,
      tour: model.tour ? getTour(model.tour) : null,
      tour_name: model.is_cruise && model.tour_name ? model.tour_name : '',
      dateId: model.tour_date || undefined,

      tour_operator_contact:
        model.tour_operator_contact != null
          ? {
            id: model.tour_operator_contact.id,
            name: model.tour_operator_contact.full_name,
            requisites: model.tour_operator_contact.requisites,
          }
          : { name: 'Акварель' },
      date: model.tour_date_start
        ? moment(model.tour_date_start).format('DD.MM.YYYY') + '-' + moment(model.tour_date_end).format('DD.MM.YYYY')
        : '',
      adults: model.adults_count || 0,
      children: model.children_count || 0,
      operatorApplicationNo: model.base_code || '',
      individual: model.is_individual || false,
      internationalPassport: model.is_foreign_passport || false,
      show_akvarel: model.show_akvarel === null ? false : model.show_akvarel,
      show_in_pass: model.show_in_pass === null ? false : model.show_in_pass,
      isAccommodation: false,
      isExtraNight: false,
      isHotelTransfer: model.is_hotel || false,
      isCruize: model.is_cruise || false,
      hotelsTrans: [],
      reserve_system_name: model.reserve_system_name,
      tourists:
        model.tourists && model.tourists.length
          ? model.tourists.map(({ id, full_name, ['@id']: string, birthday, phone, contact_passport, ...other }) => ({
            id,
            ['@id']: string,
            name: birthday
              ? phone
                ? contact_passport && contact_passport.code
                  ? `${full_name} / ${getDate(new Date(birthday))} / ${contact_passport.code} / ${phone}`
                  : `${full_name} / ${getDate(new Date(birthday))} / ${phone}`
                : `${full_name} / ${getDate(new Date(birthday))}`
              : full_name || getName(other),
          }))
          : [],
      hotels:
        model.placements && model.placements.length
          ? model.placements.map((hotel) => {
            const type = listOfIdsTypePlacements.find((i) => i.id.toString() === hotel.placement_type.toString());
            const count = nightCountsList.find((el) => el.id === hotel.nights_count);
            return {
              id: hotel.id ? hotel.id : null,
              hotel: hotel.hotel_string,
              period_string: hotel.period,
              nights_count: count ? count.value : '',
              type: hotel.room_type_string,
              category: hotel.room_category_string,
              period:
                hotel.date_start != null && hotel.date_end != null
                  ? [new Date(hotel.date_start), new Date(hotel.date_end)]
                  : [null, null],
              address: hotel.address,
              boat_string: hotel.boat_string,
              room_level_string: hotel.room_level_string,
              room_number: hotel.room_number,
              food_type: hotel.food_type,
              accommodation: type ? type.value : '',
              price: Number(hotel.price),
              tourist: hotel.tourists.map((el: any) =>
                el.birthday
                  ? el.phone
                    ? el.contact_passport && el.contact_passport.code
                      ? `${el.full_name} / ${getDate(new Date(el.birthday))} / ${el.contact_passport.code} / ${el.phone
                      }`
                      : `${el.full_name} / ${getDate(new Date(el.birthday))} / ${el.phone}`
                    : `${el.full_name} / ${getDate(new Date(el.birthday))}`
                  : el.full_name
              ),
            };
          })
          : [],
      extraNights:
        model.order_additional_nights && model.order_additional_nights.length
          ? model.order_additional_nights.map((hotel) => {
            const type = listOfIdsTypePlacements.find((i) => i.id.toString() === hotel.placement_type.toString());
            return {
              id: hotel.id ? hotel.id : null,
              period:
                hotel.date_start && hotel.date_end
                  ? [new Date(hotel.date_start), new Date(hotel.date_end)]
                  : [null, null],
              hotel: hotel.hotel_string,
              type: hotel.room_type_string,
              category: hotel.room_category_string,
              food_type: hotel.food_type,
              accommodation: type ? type.value : '',
              price: Number(hotel.price),
              tourist: hotel.tourists.map((el: any) =>
                el.birthday
                  ? el.phone
                    ? el.contact_passport && el.contact_passport.code
                      ? `${el.full_name} / ${getDate(new Date(el.birthday))} / ${el.contact_passport.code} / ${el.phone
                      }`
                      : `${el.full_name} / ${getDate(new Date(el.birthday))} / ${el.phone}`
                    : `${el.full_name} / ${getDate(new Date(el.birthday))}`
                  : el.full_name
              ),
              comission: hotel.comission,
            };
          })
          : [],
      extraService:
        model.services_array && model.services_array.length
          ? model.services_array.map((service) => ({
            service: service.name,
            price: service.price ? String(service.price) : '',
            quantity: service.quantity,
            sum: +service.summ,
            description: service.description,
          }))
          : [],
      sales:
        model.sales_array && model.sales_array.length
          ? model.sales_array.map((sale) => ({
            name: sale.name,
            sale: sale.sale_value ? sale.sale_value : '',
            quantity: String(sale.quantity),
          }))
          : [],
      transfers:
        model.order_transfers && model.order_transfers.length
          ? model.order_transfers.map((el) => ({
            departurePoint: el.start_place,
            route: el.plan,
            departureDate: new Date(el.start_date),
            departureTime: el.start_time ? getTime(new Date(el.start_time)) : '',
            transportType: el.transport_type,
            transportCategory: el.transport_form,
            places_count: el.places_count != null ? el.places_count.toString() : '',
            price: el.price != null ? el.price.slice(0, -3) : '',
          }))
          : [],
      fullDescription: model.transportation,
      base_price: model.base_price,
    },
    documentsAndPayment: {
      exchange_rate: model.exchange_rate,
      base_summ: model.base_summ ? model.base_summ : '0',
      changed_baseSum: '0',
      total_amount: model.total_amount ? model.total_amount : '0',
      agent_reward: model.agent_reward ? model.agent_reward : '0',
      services_summ: model.services_summ ? model.services_summ : '0',
      additional_nights_summ: model.additional_nights_summ ? model.additional_nights_summ : '0',
      sales_summ: model.sales_summ ? model.sales_summ : '0',
      manager_sale: model.manager_sale ? model.manager_sale : '0',
      manager_sale_percent: model.manager_sale_percent ? model.manager_sale_percent : '0',
      supplierPrice: model.provider_price ? model.provider_price : '0',
      total_summ: model.total_summ ? model.total_summ : '0',
      real_payments: model.real_payments
        ? model.real_payments.map((el) => {
          return {
            id: el.id,
            method: getPaymentMethodEnToRu(el.method),
            date: getDate(new Date(el.date)),
            summ: el.summ,
            rate: el.exchange_rate ? el.exchange_rate : '1',
          };
        })
        : [],
      planned_payments: model.planned_payments
        ? model.planned_payments.map((el) => {
          return {
            id: el.id,
            type: getPaymentTypeEnToRu(el.type),
            summ: el.summ,
            plan_date: getDate(new Date(el.plan_date)),
            rate: el.exchange_rate,
          };
        })
        : [],
      order_documents: model.order_documents ? model.order_documents : [],
      payment_links: model.payment_links ? model.payment_links : [],
      payer: model.payer?.id
        ? {
          id: model.payer.id,
          name: model.payer.full_name,
          //email: model.payer.email,
          email: model.contact.subject === 'juridical' ? model.contact_person_email || model.payer.email : model.payer.email,
          order: model.payer.order,
          responsible: model.payer.responsible,
        }
        : undefined,
    },
    manager: model.manager && {
      id: model.manager.id,
      avatar: undefined,
      name: getName(model.manager),
    },
    user: model.contact.user ? model.contact.user['@id'] : '',
  };
};
