import React, { FC, useContext, useEffect, useRef, useState } from 'react';

import { TabsPanel } from 'ui/TabsPanel/TabsPanel';
import { Controls } from 'components/Controls';
import { OrderFormType } from './OrderForm.types';
import { resetPreSaveOrder, setIsShared, setPreSavePresentOrder } from 'store/entities/orders/ordersSlice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { OrderFormTabs, OrderStatus } from 'core/constants/enums';

import styles from './OrderForm.module.scss';
import { TourInformation } from './TourInfoTab';
import { DocumentAndPaymentTab } from './DocumentsAndPaymentTab';
import ModalContext from '../../../../core/context/ModalContext';
import { notifications } from '@mantine/notifications';
import { listOfIdsOrderStatuses, listOfIdsTypePlacements, nightCountsList } from '../../../../core/constants/lists';
import { ReminderType } from 'components/Reminders/Reminders.type';
import BasicInfoTab from './BasicInfoTab';
import { getDate } from 'core/utils/getDate';
import { Order } from '../../../../services/orders/orderApi.service';
import { updateOrder, addOrder } from 'store/action-creators/orders';
import {
  getTourDates,
  orderAddServicesOut,
  orderNotificationOut,
  orderPlannedPaymentOut,
  orderRealPaymentOut,
  orderSalesOut,
  orderTouristsOut,
  orderTransferOut,
} from 'services/orders/order.factory';
import { resetSelectedTour } from 'store/entities/tours/toursSlice';
import { getStatusToServer } from 'core/utils/getStatus';
import { ConsultationTab } from './ConsultationTab';
import { isPayloadValid } from 'core/utils/isEmptyObject';
import { Button } from 'ui/Button';
import { updateOrderDocumentAPI } from 'services/orderDocument/orderDocumentApi.service';

export const OrderForm: FC<OrderFormType> = ({ order }) => {
  const [tab, setTab] = useState<string>(OrderFormTabs.BASIC_INFO);
  const dispatch = useAppDispatch();
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [crunch, setCrunch] = useState<boolean>(false);
  useEffect(() => {
    let timeout: NodeJS.Timeout | undefined;
    if (crunch) {
      setCrunch(false);
      timeout = setTimeout(() => {
        setIsDirty(true);
      }, 700);
    }
  }, [crunch]);

  const { preSaveOrder, isSuccess, isError, isLoading } = useAppSelector((state) => state.orders);
  const { currentUser = null } = useAppSelector((state) => state.users);
  const tourDateList = useAppSelector((state) => state.tours.tourDateList);
  const tourIds = tourDateList.map((item) => {
    return {
      id: item.id,
      tour: getDate(new Date(item.start)) + '-' + getDate(new Date(item.ending)),
    };
  });
  const prepareOrder = () => {
    const tour_date =
      preSaveOrder.tourInfo && preSaveOrder.tourInfo.date
        ? tourIds.find((el) => el.tour.toString() === preSaveOrder!.tourInfo!.date!.toString())
        : undefined;
    // TODO: Переделать везде
    const tourDates = getTourDates(preSaveOrder);

    // сбор размещений
    const newPlacements =
      preSaveOrder.tourInfo && preSaveOrder.tourInfo.hotels.length > 0
        ? preSaveOrder.tourInfo.hotels.map((hotel) => {
            const newArrTurists = hotel.tourist.map((el) => {
              const newId = preSaveOrder.tourInfo?.tourists.find((partner) => partner.name === el);
              return newId && `/api/v1/contacts/${newId.id.toString()}`;
            });
            const nightCount = hotel.nights_count
              ? Number(nightCountsList.find((el) => el.value === String(hotel.nights_count))?.id)
              : 0;
            const periodSplit =
              hotel.period_string && hotel.period_string != '' ? hotel.period_string.split('-') : null;
            if (hotel.id) {
              return {
                '@id': `/api/v1/order-person-placements/${hotel.id}`,
                id: hotel.id,
                date_start:
                  (preSaveOrder.tourInfo?.isCruize || preSaveOrder.tourInfo?.isHotelTransfer) && hotel.period[0] != null
                    ? getDate(new Date(hotel.period[0]!))
                    : periodSplit != null
                    ? periodSplit[0]
                    : null,
                date_end:
                  (preSaveOrder.tourInfo?.isCruize || preSaveOrder.tourInfo?.isHotelTransfer) && hotel.period[1] != null
                    ? getDate(new Date(hotel.period[1]!))
                    : periodSplit != null
                    ? periodSplit[1]
                    : null,
                period: hotel.period_string,
                nights_count: nightCount,
                address: hotel.address,
                boat_string: hotel.boat_string,
                room_level_string: hotel.room_level_string,
                room_number: hotel.room_number,
                hotel_string: hotel.hotel,
                room_type_string: hotel.type,
                food_type: hotel.food_type,
                room_category_string: hotel.category,
                placement_type:
                  hotel.accommodation === 'Реб.'
                    ? 2
                    : Number(listOfIdsTypePlacements.find((i) => i.value === hotel.accommodation)?.id) || 0,
                price: hotel.price.toString() != '' ? hotel.price.toString() : '0',
                tourists: newArrTurists.length > 0 ? newArrTurists.filter((el) => el != null) : [],
              };
            } else {
              return {
                date_start:
                  (preSaveOrder.tourInfo?.isCruize || preSaveOrder.tourInfo?.isHotelTransfer) && hotel.period[0] != null
                    ? getDate(new Date(hotel.period[0]!))
                    : periodSplit != null
                    ? periodSplit[0]
                    : null,
                date_end:
                  (preSaveOrder.tourInfo?.isCruize || preSaveOrder.tourInfo?.isHotelTransfer) && hotel.period[1] != null
                    ? getDate(new Date(hotel.period[1]!))
                    : periodSplit != null
                    ? periodSplit[1]
                    : null,
                period: hotel.period_string,
                nights_count: nightCount,
                address: hotel.address,
                boat_string: hotel.boat_string,
                room_level_string: hotel.room_level_string,
                room_number: hotel.room_number,
                hotel_string: hotel.hotel,
                room_type_string: hotel.type,
                food_type: hotel.food_type,
                room_category_string: hotel.category,
                placement_type:
                  hotel.accommodation === 'Реб.'
                    ? 2
                    : Number(listOfIdsTypePlacements.find((i) => i.value === hotel.accommodation)?.id) || 0,
                price: hotel.price.toString() != '' ? hotel.price.toString() : '0',
                tourists: newArrTurists.length > 0 ? newArrTurists.filter((el) => el != null) : [],
              };
            }
          })
        : [];
    // сбор доп.ночей
    const newNights =
      preSaveOrder.tourInfo && preSaveOrder.tourInfo.extraNights.length > 0
        ? preSaveOrder.tourInfo.extraNights.map((hotel) => {
            const newArrTurists = hotel.tourist.map((el) => {
              const newId = preSaveOrder.tourInfo?.tourists.find((partner) => partner.name === el);
              return newId && `/api/v1/contacts/${newId.id.toString()}`;
            });
            return {
              '@id': hotel.id ? `/api/v1/order-additional-nights/${hotel.id}` : undefined,
              id: hotel.id ? `/api/v1/order-additional-nights/${hotel.id}` : undefined,
              date_start: hotel.period[0] != null ? getDate(new Date(hotel.period[0]!)) : undefined,
              date_end: hotel.period[1] != null ? getDate(new Date(hotel.period[1]!)) : undefined,
              hotel_string: hotel.hotel,
              room_type_string: hotel.type,
              room_category_string: hotel.category,
              food_type: hotel.food_type,
              placement_type: Number(listOfIdsTypePlacements.find((i) => i.value === hotel.accommodation)?.id) || 0,
              price: hotel.price.toString() != '' ? hotel.price.toString() : '0',
              tourists: newArrTurists.length > 0 ? newArrTurists.filter((el) => el != null) : [],
              comission: hotel.comission === '' ? '0' : hotel.comission.toString(),
            };
          })
        : [];
    const newBasePrice = preSaveOrder.tourInfo?.base_price ? preSaveOrder.tourInfo?.base_price : '0';

    const baseSum =
      preSaveOrder.documentsAndPayment && +preSaveOrder.documentsAndPayment.changed_baseSum > 0
        ? preSaveOrder.documentsAndPayment.changed_baseSum
        : Number(
            preSaveOrder.tourInfo?.hotels?.reduce((accumulator, object) => {
              return accumulator + Number(object.price);
            }, 0)
          ) +
            Number(
              preSaveOrder.tourInfo?.transfers?.reduce((accumulator, object) => {
                return accumulator + Number(object.price);
              }, 0)
            ) ===
          0
        ? Number(preSaveOrder.tourInfo?.base_price)
        : Number(
            preSaveOrder.tourInfo?.hotels?.reduce((accumulator, object) => {
              return accumulator + Number(object.price);
            }, 0)
          ) +
          Number(
            preSaveOrder.tourInfo?.transfers?.reduce((accumulator, object) => {
              return accumulator + Number(object.price);
            }, 0)
          );

    // Формирование суммы доп.ночей
    const extraNightSum =
      preSaveOrder.tourInfo?.extraNights.reduce((accumulator, object) => {
        return accumulator + Number(object.price);
      }, 0) || 0;

    // Формирование суммы доп.услуг
    const extraServiceSum =
      preSaveOrder.tourInfo?.extraService.reduce((accumulator, object) => {
        return accumulator + Number(object.price) * object.quantity;
      }, 0) || 0;

    // Формирование суммы скидок
    const extraSalesSum =
      preSaveOrder.tourInfo?.sales.reduce((accumulator, object) => {
        return accumulator + Number(object.sale) * Number(object.quantity);
      }, 0) || 0;
    const serviceSum = +baseSum + extraNightSum + extraServiceSum;
    const allPrice = serviceSum - extraSalesSum - Number(preSaveOrder.documentsAndPayment?.agent_reward);
    const persent = preSaveOrder.documentsAndPayment?.manager_sale_percent
      ? (allPrice * +preSaveOrder.documentsAndPayment?.manager_sale_percent) / 100
      : 0;
    const allSum =
      preSaveOrder?.documentsAndPayment && persent === 0
        ? allPrice - +preSaveOrder?.documentsAndPayment?.manager_sale
        : allPrice - persent;

    const passPlacementArr = preSaveOrder.tourInfo?.pass_placements_array?.map((el) => {
      const newArrTurists = el.tourists.map((el) => {
        const newId = preSaveOrder.tourInfo?.tourists.find((partner) => partner.name === el);
        return newId && `/api/v1/contacts/${newId.id.toString()}`;
      });
      return {
        country: el.country,
        city: el.city,
        region: el.region,
        hotel: el.hotel,
        place_type: el.place_type,
        period_start: el?.period && el?.period[0] != null ? new Date(el.period[0]!) : undefined,
        period_end: el?.period && el?.period[1] != null ? new Date(el.period[1]!) : undefined,
        tourists: newArrTurists.length > 0 ? newArrTurists.filter((el) => el != null) : [],
      };
    });
    const data = {
      contact: `/api/v1/contacts/${preSaveOrder.basicInfo?.partner && preSaveOrder.basicInfo.partner.id}`,
      contact_person_full_name: preSaveOrder.basicInfo?.contactPerson,
      contact_person_email: preSaveOrder.basicInfo?.email,
      contact_person_phone: preSaveOrder.basicInfo?.phone,
      manager: `/api/v1/users/${
        preSaveOrder.basicInfo?.manager ? preSaveOrder.basicInfo.manager.id : currentUser && currentUser.id
      }`,
      status: `/api/v1/order-statuses/${
        listOfIdsOrderStatuses.find((i) => i.value === preSaveOrder.basicInfo!.status)?.id
      }`,
      payed_status: preSaveOrder.basicInfo?.payed_status && getStatusToServer(preSaveOrder.basicInfo?.payed_status),
      return_summ: preSaveOrder.basicInfo?.refundAmount ? preSaveOrder.basicInfo.refundAmount.toString() : '0',
      tour:
        preSaveOrder.tourInfo && preSaveOrder.tourInfo.tour && preSaveOrder.tourInfo.tour.id != 0
          ? `/api/v1/tours/${preSaveOrder.tourInfo.tour!.id}`
          : undefined,
      children_count: Number(preSaveOrder.tourInfo?.children),
      adults_count: Number(preSaveOrder.tourInfo?.adults),
      presentation: preSaveOrder.presentation,
      base_price: newBasePrice,
      base_code: preSaveOrder.tourInfo?.operatorApplicationNo,
      is_individual: preSaveOrder.tourInfo?.individual,
      is_foreign_passport: preSaveOrder.tourInfo?.internationalPassport,
      is_hotel: preSaveOrder.tourInfo?.isHotelTransfer,
      is_cruise: preSaveOrder.tourInfo?.isCruize,
      isShared: preSaveOrder.isShared,
      tour_type: preSaveOrder.tourInfo?.isCruize ? 'cruise' : preSaveOrder.tourInfo?.isHotelTransfer ? 'hotel' : 'tour',
      reserve_system_name: preSaveOrder.tourInfo?.reserve_system_name,
      tour_name: preSaveOrder.tourInfo?.isCruize
        ? preSaveOrder.tourInfo.tour_name
        : preSaveOrder.tourInfo?.isHotelTransfer && preSaveOrder.tourInfo.hotels.length > 0
        ? preSaveOrder.tourInfo.hotels[0].hotel
        : preSaveOrder.tourInfo?.tour?.title,
      tour_date_start: tourDates[0] || null,
      tour_date_end: tourDates[1] || null,
      show_akvarel: preSaveOrder.tourInfo?.show_akvarel,
      tour_date: tour_date ? `/api/v1/tour-dates/${tour_date?.id}` : null,
      tourists: preSaveOrder.tourInfo?.tourists.map((tourist) => orderTouristsOut(tourist)).filter((i) => i),
      placements: newPlacements,
      order_additional_nights: newNights,
      services_array: preSaveOrder.tourInfo?.extraService.map((service) => orderAddServicesOut(service)),
      sales_array: preSaveOrder.tourInfo?.sales.map((sale) => orderSalesOut(sale)),
      order_transfers: preSaveOrder.tourInfo?.transfers.map((transfer) => orderTransferOut(transfer)),
      transportation: preSaveOrder.tourInfo?.fullDescription,
      base_summ: baseSum.toString(),
      services_summ: extraServiceSum.toString(),
      show_in_pass: preSaveOrder.tourInfo?.show_in_pass,
      additional_nights_summ: extraNightSum.toString(),
      sales_summ: extraSalesSum.toString(),
      manager_sale: preSaveOrder.documentsAndPayment && preSaveOrder.documentsAndPayment.manager_sale,
      provider_price: preSaveOrder.documentsAndPayment && preSaveOrder.documentsAndPayment.supplierPrice,
      manager_sale_percent: preSaveOrder.documentsAndPayment && preSaveOrder.documentsAndPayment.manager_sale_percent,
      total_amount: String(serviceSum),
      agent_reward: preSaveOrder.documentsAndPayment?.agent_reward,
      total_summ: allSum.toString(),
      pass_placements_array: passPlacementArr,
      pass_tour_dates_array: preSaveOrder.tourInfo?.pass_tour_dates_array,
      tour_operator_contact: preSaveOrder.tourInfo?.tour_operator_contact
        ? preSaveOrder.tourInfo?.tour_operator_contact.name === 'Акварель'
          ? null
          : `/api/v1/contacts/${preSaveOrder.tourInfo?.tour_operator_contact?.id}`
        : null,
      payer:
        preSaveOrder.documentsAndPayment && preSaveOrder.documentsAndPayment.payer?.id
          ? `/api/v1/contacts/${preSaveOrder.documentsAndPayment.payer?.id}`
          : undefined,
      order_consultation: isPayloadValid(preSaveOrder.order_consultation) ? null : preSaveOrder.order_consultation,
      exchange_rate: preSaveOrder.documentsAndPayment?.exchange_rate,
      contract_date: preSaveOrder.tourInfo?.contract_date,
      contract_number: preSaveOrder.tourInfo?.contract_number,
      contract_sum: preSaveOrder.tourInfo?.contract_sum,
    };
    return data;
  };
  const preSaveOrderRef = useRef(preSaveOrder);
  const preDataRef = useRef<any>(prepareOrder());
  useEffect(() => {
    if (isDirty) {
      if (
        order &&
        preSaveOrder &&
        order['@id'] &&
        JSON.stringify(preSaveOrderRef.current) !== JSON.stringify(preSaveOrder)
        /// ^^^^ в preSaveOrderRef нет инфы о туристах. и в preSaveOrder тоже, если всех удалили! С размещениями то же самое
        // причем это справедливо если первое действие при входе в инфотаб это сразу удалить одну запись.
      ) {
        const id = order['id'];
        const data = prepareOrder();

        const diff = Object.keys(data).reduce((diff, key) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          if (JSON.stringify(preDataRef.current[key]) === JSON.stringify(data[key])) return diff;
          return {
            ...diff,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            [key]: data[key],
          };
        }, {});

        dispatch(updateOrder({ ...diff, id } as unknown as Partial<Order>));
        preDataRef.current = data;
      }
      preSaveOrderRef.current = preSaveOrder;
      if (crunch) {
        setCrunch(false);
      }
    }
    setIsDirty(false);
  }, [isDirty, preSaveOrder, preDataRef]);

  const onSubmit = () => {
    // сбор дат начала и конца тура
    const tourDates =
      preSaveOrder.tourInfo && preSaveOrder.tourInfo?.isCruize && preSaveOrder.tourInfo.hotels.length > 0
        ? [
            getDate(new Date(preSaveOrder.tourInfo.hotels[0].period[0]!)),
            getDate(new Date(preSaveOrder.tourInfo.hotels[preSaveOrder.tourInfo.hotels.length - 1].period[1]!)),
          ]
        : preSaveOrder.tourInfo?.isHotelTransfer &&
          preSaveOrder.tourInfo.transfers.length > 0 &&
          preSaveOrder.tourInfo.hotels.length === 0
        ? [getDate(new Date(preSaveOrder.tourInfo.transfers[0].departureDate!)), undefined]
        : preSaveOrder.tourInfo?.isHotelTransfer &&
          preSaveOrder.tourInfo.transfers.length > 0 &&
          preSaveOrder.tourInfo.hotels.length > 0
        ? [
            getDate(new Date(preSaveOrder.tourInfo.transfers[0].departureDate!)),
            getDate(new Date(preSaveOrder.tourInfo.hotels[preSaveOrder.tourInfo.hotels.length - 1].period[1]!)),
          ]
        : preSaveOrder.tourInfo?.isHotelTransfer &&
          preSaveOrder.tourInfo.transfers.length === 0 &&
          preSaveOrder.tourInfo.hotels.length > 0
        ? [
            getDate(new Date(preSaveOrder.tourInfo.hotels[0].period[0]!)),
            getDate(new Date(preSaveOrder.tourInfo.hotels[preSaveOrder.tourInfo.hotels.length - 1].period[1]!)),
          ]
        : preSaveOrder.tourInfo?.date
        ? preSaveOrder.tourInfo.date.toString().split('-')
        : null;

    // сбор размещений
    const newPlacements =
      preSaveOrder.tourInfo && preSaveOrder.tourInfo.hotels.length > 0
        ? preSaveOrder.tourInfo.hotels.map((hotel) => {
            const newArrTurists = hotel.tourist.map((el) => {
              const newId = preSaveOrder.tourInfo?.tourists.find((partner) => partner.name === el);
              return newId && `/api/v1/contacts/${newId.id.toString()}`;
            });
            const nightCount = hotel.nights_count
              ? Number(nightCountsList.find((el) => el.value === String(hotel.nights_count))?.id)
              : 0;
            const type =
              hotel.accommodation === 'Реб.'
                ? 2
                : Number(listOfIdsTypePlacements.find((i) => i.value === hotel.accommodation)?.id);
            const periodSplit =
              hotel.period_string && hotel.period_string != '' ? hotel.period_string.split('-') : null;
            return {
              date_start:
                (preSaveOrder.tourInfo?.isCruize || preSaveOrder.tourInfo?.isHotelTransfer) && hotel.period[0] != null
                  ? getDate(new Date(hotel.period[0]!))
                  : periodSplit != null
                  ? periodSplit[0]
                  : null,
              date_end:
                (preSaveOrder.tourInfo?.isCruize || preSaveOrder.tourInfo?.isHotelTransfer) && hotel.period[1] != null
                  ? getDate(new Date(hotel.period[1]!))
                  : periodSplit != null
                  ? periodSplit[1]
                  : null,
              period: hotel.period_string,
              nights_count: nightCount,
              address: hotel.address,
              boat_string: hotel.boat_string,
              room_level_string: hotel.room_level_string,
              room_number: hotel.room_number,
              hotel_string: hotel.hotel,
              room_type_string: hotel.type,
              room_category_string: hotel.category,
              food_type: hotel.food_type,
              placement_type: type ? type : 0,
              price: hotel.price.toString() != '' ? hotel.price.toString() : '0',
              tourists: newArrTurists.length > 0 ? newArrTurists : [],
            };
          })
        : [];
    // сбор доп.ночей
    const newNights =
      preSaveOrder.tourInfo && preSaveOrder.tourInfo.extraNights.length > 0
        ? preSaveOrder.tourInfo.extraNights.map((hotel) => {
            const newArrTurists = hotel.tourist.map((el) => {
              const newId = preSaveOrder.tourInfo?.tourists.find((partner) => partner.name === el);
              return newId && `/api/v1/contacts/${newId.id.toString()}`;
            });
            const type = Number(listOfIdsTypePlacements.find((i) => i.value === hotel.accommodation)?.id);
            return {
              id: hotel.id ? `/api/v1/order-additional-nights/${hotel.id}` : undefined,
              date_start: hotel.period[0] != null ? getDate(new Date(hotel.period[0]!)) : undefined,
              date_end: hotel.period[1] != null ? getDate(new Date(hotel.period[1]!)) : undefined,
              hotel_string: hotel.hotel,
              room_type_string: hotel.type,
              room_category_string: hotel.category,
              food_type: hotel.food_type,
              placement_type: type ? type : 0,
              price: hotel.price.toString() != '' ? hotel.price.toString() : '0',
              tourists: newArrTurists.length > 0 ? newArrTurists.filter((el) => el != null) : [],
              comission: hotel.comission === '' ? '0' : hotel.comission.toString(),
            };
          })
        : [];
    // сбор дат тура
    const tour_date =
      preSaveOrder.tourInfo && preSaveOrder.tourInfo.date
        ? tourIds.find((el) => el.tour.toString() === preSaveOrder!.tourInfo!.date!.toString())
        : undefined;

    const newBasePrice = preSaveOrder.tourInfo?.base_price ? preSaveOrder.tourInfo?.base_price : '0';

    const baseSum =
      preSaveOrder.documentsAndPayment && +preSaveOrder.documentsAndPayment.changed_baseSum > 0
        ? preSaveOrder.documentsAndPayment.changed_baseSum
        : Number(
            preSaveOrder.tourInfo?.hotels?.reduce((accumulator, object) => {
              return accumulator + Number(object.price);
            }, 0)
          ) +
            Number(
              preSaveOrder.tourInfo?.transfers?.reduce((accumulator, object) => {
                return accumulator + Number(object.price);
              }, 0)
            ) ===
          0
        ? Number(preSaveOrder.tourInfo?.base_price)
        : Number(
            preSaveOrder.tourInfo?.hotels?.reduce((accumulator, object) => {
              return accumulator + Number(object.price);
            }, 0)
          ) +
          Number(
            preSaveOrder.tourInfo?.transfers?.reduce((accumulator, object) => {
              return accumulator + Number(object.price);
            }, 0)
          );
    // Формирование суммы доп.ночей
    const extraNightSum =
      preSaveOrder.tourInfo?.extraNights.reduce((accumulator, object) => {
        return accumulator + Number(object.price);
      }, 0) || 0;

    // Формирование суммы доп.услуг
    const extraServiceSum =
      preSaveOrder.tourInfo?.extraService.reduce((accumulator, object) => {
        return accumulator + Number(object.price) * object.quantity;
      }, 0) || 0;
    const passPlacementArr = preSaveOrder.tourInfo?.pass_placements_array?.map((el) => {
      const newArrTurists = el.tourists.map((el) => {
        const newId = preSaveOrder.tourInfo?.tourists.find((partner) => partner.name === el);
        return newId && `/api/v1/contacts/${newId.id.toString()}`;
      });
      return {
        country: el.country,
        city: el.city,
        region: el.region,
        hotel: el.hotel,
        place_type: el.place_type,
        period_start: el?.period && el?.period[0] != null ? new Date(el.period[0]!) : undefined,
        period_end: el?.period && el?.period[1] != null ? new Date(el.period[1]!) : undefined,
        tourists: newArrTurists.length > 0 ? newArrTurists.filter((el) => el != null) : [],
      };
    });
    // Формирование суммы скидок
    const extraSalesSum =
      preSaveOrder.tourInfo?.sales.reduce((accumulator, object) => {
        return accumulator + Number(object.sale) * Number(object.quantity);
      }, 0) || 0;
    const serviceSum = +baseSum + extraNightSum + extraServiceSum;
    const allPrice = serviceSum - extraSalesSum - Number(preSaveOrder.documentsAndPayment?.agent_reward);
    const persent = preSaveOrder.documentsAndPayment?.manager_sale_percent
      ? (allPrice * +preSaveOrder.documentsAndPayment?.manager_sale_percent) / 100
      : 0;
    const allSum =
      preSaveOrder?.documentsAndPayment && persent === 0
        ? allPrice - +preSaveOrder?.documentsAndPayment?.manager_sale
        : allPrice - persent;
    // сбор всех данных
    const data = {
      contact:
        preSaveOrder.basicInfo && preSaveOrder.basicInfo.partner
          ? `/api/v1/contacts/${preSaveOrder.basicInfo.partner.id}`
          : null,
      contact_person_full_name: preSaveOrder.basicInfo?.contactPerson,
      contact_person_email: preSaveOrder.basicInfo?.email,
      contact_person_phone: preSaveOrder.basicInfo?.phone,
      manager: `/api/v1/users/${
        preSaveOrder.basicInfo && preSaveOrder.basicInfo.manager
          ? preSaveOrder.basicInfo.manager.id
          : currentUser && currentUser.id
      }`,
      status: `/api/v1/order-statuses/${
        listOfIdsOrderStatuses.find((i) => i.value === preSaveOrder.basicInfo!.status)?.id
      }`,
      payed_status: preSaveOrder.basicInfo?.payed_status && getStatusToServer(preSaveOrder.basicInfo?.payed_status),
      return_summ:
        preSaveOrder.basicInfo && preSaveOrder.basicInfo.refundAmount
          ? preSaveOrder.basicInfo.refundAmount.toString()
          : '0',
      order_notifications: preSaveOrder.basicInfo?.reminders?.map((el: ReminderType) => orderNotificationOut(el)),
      tour:
        preSaveOrder.tourInfo && preSaveOrder.tourInfo.tour && preSaveOrder.tourInfo.tour.id != 0
          ? `/api/v1/tours/${preSaveOrder.tourInfo.tour!.id}`
          : undefined,
      tour_date: tour_date ? `/api/v1/tour-dates/${tour_date?.id}` : null,
      children_count: preSaveOrder.tourInfo && Number(preSaveOrder.tourInfo.children),
      adults_count: preSaveOrder.tourInfo && Number(preSaveOrder.tourInfo.adults),
      base_price: newBasePrice,
      reserve_system_name: preSaveOrder.tourInfo?.reserve_system_name,
      show_akvarel: preSaveOrder.tourInfo?.show_akvarel,
      base_code: preSaveOrder.tourInfo && preSaveOrder.tourInfo.operatorApplicationNo,
      is_individual: preSaveOrder.tourInfo && preSaveOrder.tourInfo.individual,
      is_foreign_passport: preSaveOrder.tourInfo && preSaveOrder.tourInfo.internationalPassport,
      is_hotel: preSaveOrder.tourInfo && preSaveOrder.tourInfo.isHotelTransfer,
      is_cruise: preSaveOrder.tourInfo && preSaveOrder.tourInfo.isCruize,
      tour_type: preSaveOrder.tourInfo?.isCruize ? 'cruise' : preSaveOrder.tourInfo?.isHotelTransfer ? 'hotel' : 'tour',
      tour_name: preSaveOrder.tourInfo?.isCruize
        ? preSaveOrder.tourInfo.tour_name
        : preSaveOrder.tourInfo?.isHotelTransfer && preSaveOrder.tourInfo.hotels.length > 0
        ? preSaveOrder.tourInfo.hotels[0].hotel
        : preSaveOrder.tourInfo?.tour?.title,
      tour_date_start: tourDates ? tourDates![0] : undefined,
      tour_date_end: tourDates ? tourDates![1] : undefined,
      tourists: preSaveOrder.tourInfo?.tourists.map((tourist) => orderTouristsOut(tourist)),
      placements: newPlacements,
      order_additional_nights: newNights,
      services_array: preSaveOrder.tourInfo?.extraService.map((service) => orderAddServicesOut(service)),
      sales_array: preSaveOrder.tourInfo?.sales.map((sale) => orderSalesOut(sale)),
      order_transfers: preSaveOrder.tourInfo?.transfers.map((transfer) => orderTransferOut(transfer)),
      transportation: preSaveOrder.tourInfo && preSaveOrder.tourInfo.fullDescription,
      base_summ: baseSum.toString(),
      services_summ: extraServiceSum.toString(),
      additional_nights_summ: extraNightSum.toString(),
      sales_summ: extraSalesSum.toString(),
      manager_sale: preSaveOrder.documentsAndPayment && preSaveOrder.documentsAndPayment.manager_sale,
      provider_price: preSaveOrder.documentsAndPayment && preSaveOrder.documentsAndPayment.supplierPrice,
      manager_sale_percent: preSaveOrder.documentsAndPayment && preSaveOrder.documentsAndPayment.manager_sale_percent,
      total_summ: allSum.toString(),
      total_amount: String(serviceSum),
      agent_reward: preSaveOrder.documentsAndPayment?.agent_reward,
      real_payments: preSaveOrder.documentsAndPayment
        ? preSaveOrder.documentsAndPayment.real_payments.map((el) => orderRealPaymentOut(el))
        : [],
      planned_payments: preSaveOrder.documentsAndPayment
        ? preSaveOrder.documentsAndPayment.planned_payments.map((el) => orderPlannedPaymentOut(el))
        : [],
      payer:
        preSaveOrder.documentsAndPayment && preSaveOrder.documentsAndPayment.payer?.id
          ? `/api/v1/contacts/${preSaveOrder.documentsAndPayment.payer?.id}`
          : undefined,
      tour_operator_contact: preSaveOrder.tourInfo?.tour_operator_contact
        ? preSaveOrder.tourInfo?.tour_operator_contact.name === 'Акварель'
          ? null
          : `/api/v1/contacts/${preSaveOrder.tourInfo?.tour_operator_contact?.id}`
        : null,
      order_consultation: isPayloadValid(preSaveOrder.order_consultation) ? null : preSaveOrder.order_consultation,
      exchange_rate: preSaveOrder.documentsAndPayment?.exchange_rate,
      show_in_pass: preSaveOrder.tourInfo?.show_in_pass,
      pass_placements_array: passPlacementArr,
      pass_tour_dates_array: preSaveOrder.tourInfo?.pass_tour_dates_array,
      contract_date: preSaveOrder.tourInfo?.contract_date,
      contract_number: preSaveOrder.tourInfo?.contract_number,
      contract_sum: preSaveOrder.tourInfo?.contract_sum,
    };
    console.log('> OrderForm, addOrder data: ', data.order_consultation);
    dispatch(addOrder(data));
  };
  //проверка успешности отправки запроса. если ок, закрываем окно, если нет - выводим сообщение об ошибке
  useEffect(() => {
    if (isSuccess === 'ADD_ORDER') {
      notifications.show({
        title: 'Успех!',
        color: 'green',
        message: 'Заказ успешно сохранен!',
      });
      closeForm();
    } else if (isError === 'ADD_ORDER') {
      notifications.show({
        title: 'Ошибка',
        color: 'red',
        message: 'Ошибка сохранения данных на сервер',
      });
    }
  }, [isSuccess, isError]);
  const { setActive } = useContext(ModalContext);
  const closeForm = () => {
    setActive(false);
    dispatch(resetPreSaveOrder());
    dispatch(resetSelectedTour());
  };
  const notEdit =
    preSaveOrder.basicInfo?.status === OrderStatus.FINISHED ||
    preSaveOrder.basicInfo?.status === OrderStatus.CANCELLED ||
    preSaveOrder.basicInfo?.status === OrderStatus.REFUSAL;

  console.log(preSaveOrder.documentsAndPayment?.order_documents);
  return (
    <>
      {preSaveOrder.id && (
        <Button
          className={styles.lkBtn}
          onClick={() => {
            preSaveOrder.documentsAndPayment?.order_documents.map(async (el) => {
              await updateOrderDocumentAPI({ id: el.id, transferred_to_user: true });
            });
            if (preSaveOrder.isShared) {
              dispatch(setPreSavePresentOrder(preSaveOrder));

              setCrunch(true);
              return;
            }
            dispatch(setIsShared(!preSaveOrder.isShared));

            setCrunch(true);
          }}
        >
          {preSaveOrder.isShared ? 'Обновить в ЛК' : 'Отобразить в ЛК'}
        </Button>
      )}
      <TabsPanel
        tab={tab}
        tabs={Object.values(OrderFormTabs)}
        color={'#F7A860'}
        setActive={setTab}
        className={styles.tabs}
      />

      {tab === OrderFormTabs.BASIC_INFO && (
        <BasicInfoTab
          basicInfo={order?.basicInfo}
          setDirty={() => {
            setCrunch(true);
          }}
          isCanEdit={!notEdit}
        />
      )}

      {tab === OrderFormTabs.CONSULTATION && (
        <ConsultationTab
          consultation={order?.order_consultation}
          setDirty={() => {
            setCrunch(true);
          }}
        />
      )}
      {tab === OrderFormTabs.TOUR_INFO && (
        <TourInformation
          tourInfo={order?.tourInfo}
          setDirty={() => {
            setCrunch(true);
          }}
          isCanEdit={!notEdit}
        />
      )}

      {tab === OrderFormTabs.DOCUMENTS_AND_PAYMENT && (
        <DocumentAndPaymentTab
          tourInfo={order?.tourInfo}
          documentsAndPayment={order?.documentsAndPayment}
          setDirty={() => {
            setCrunch(true);
          }}
          isCanEdit={!notEdit}
        />
      )}

      {order === undefined && (
        <Controls
          btnSaveHandler={onSubmit}
          btnCancelHandler={closeForm}
          btnSaveLabel="Создать"
          btnCancelLabel="Отмена"
          disabledSave={isLoading === 'ADD_ORDER'}
        />
      )}
    </>
  );
};
