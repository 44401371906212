import React, { FC, useEffect, useState } from 'react';
import { PrivatePerson } from '../components/PartnerFormPrivatePerson/PartnerFormPrivatePerson.types';
import { StatusPartner } from 'core/constants/enums';
import { PartnerFormLegalPerson } from '../components/PartnerFormLegalPerson';
import { PartnerFormPrivatePerson } from '../components/PartnerFormPrivatePerson';

import styles from './PartnerEdit.module.scss';
import { LegalPerson } from '../components/PartnerFormLegalPerson/PartnerFormLegalPerson.types';
import { getStatusLKToServer } from 'core/utils/getStatus';
import { useAppSelector } from 'store/hooks';

interface PartnerEditProps {
  partner: PrivatePerson | LegalPerson | null;
}

export const PartnerEdit: FC<PartnerEditProps> = ({ partner }) => {
  const { preSaveLegalPerson } = useAppSelector((state) => state.partners);
  return (
    <section className={styles.edit}>
      <div className={styles.top}>
        <div className={styles.nameHeader}>
          {partner?.status === StatusPartner.LEGAL_PERSON ? (
            <>
              <h2>{(partner as LegalPerson).commonInfo.name}</h2>
            </>
          ) : (
            <>
              <h2>
                {(partner as PrivatePerson)?.commonInfo.lastName} {(partner as PrivatePerson)?.commonInfo.firstName}{' '}
                {(partner as PrivatePerson)?.commonInfo.patronymic}
              </h2>
            </>
          )}
          <div className={styles.status}>{partner?.status}</div>
        </div>
        {getStatusLKToServer(preSaveLegalPerson?.registration_status) !== 'unregistered' && (
          <div className={styles.lkTag}>Пользователь ЛК</div>
        )}
      </div>
      <div className={styles.content}>
        {partner?.status === StatusPartner.PRIVATE_PERSON ? (
          <PartnerFormPrivatePerson partner={partner as PrivatePerson} />
        ) : (
          <PartnerFormLegalPerson partner={partner as LegalPerson} />
        )}
      </div>
    </section>
  );
};
