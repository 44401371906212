import React, { FC, useEffect, useMemo, useState } from 'react';
import { Loader } from '@mantine/core';
import {
  BasicInfoTabProps,
  getPartnerObj,
  PartnersFromSearch,
  propers,
  searchPartnersUpgrate,
  statusesOrder,
  statusesPayment,
} from './BasicInfoTab.helpers';
import { useForm } from '@mantine/form';
import { BasicInfo, BasicInfoNote, OrderAction } from 'pages/Orders/Order.types';
import { SelectSearch } from 'ui/SelectSearch';
import { Modal } from 'components/Modal';
import { PartnerEdit } from 'pages/Partners/PartnerEdit';
import { Input } from 'ui/Input';
import { MailIcon, PhoneIcon, UserIcon } from 'components/icons';
import { Select } from 'ui/Select';
import { PartnerCreate } from 'pages/Partners/PartnerCreate';
import { TabsPanel } from 'ui/TabsPanel/TabsPanel';
import { User } from 'components/User';
import { TextEditorOrder } from 'components/TextEditorOrder';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setPreSaveOrder } from 'store/entities/orders/ordersSlice';
import { Reminders } from 'components/Reminders';
import { ReminderType } from 'components/Reminders/Reminders.type';
import { BasicInfoTabs, OrderActionsStatus, OrderStatus } from 'core/constants/enums';
import { OrderActions } from 'components/OrderActions';
import styles from './BasicInfoTab.module.scss';
import { addOrderAction, getOrderActions } from '../../../../../store/action-creators/orderActions';
import { SelectNew } from '../../../../../ui/SelectNew';
import {
  resetPreSaveLegalPerson,
  resetPreSavePrivatePerson,
  setIsPartnerModal,
} from 'store/entities/partners/partnersSlice';
import { getPartner } from 'store/action-creators/partners';
import { LegalPerson } from 'pages/Partners/components/PartnerFormLegalPerson/PartnerFormLegalPerson.types';
import { PrivatePerson } from 'pages/Partners/components/PartnerFormPrivatePerson/PartnerFormPrivatePerson.types';
import { RequestFromSite } from 'components/RequestFromSite';
import { getDate } from 'core/utils/getDate';
import { getOrderTabs } from 'core/utils/getOrderTabs';
import { Chat } from 'components/Chat';
import { partnersAPI } from 'services/partners/partnerApi.service';

const BasicInfoTab: FC<BasicInfoTabProps> = ({ basicInfo, setDirty, isCanEdit }) => {
  const { preSaveOrder, isResetPreSaveOrder } = useAppSelector((state) => state.orders);
  const { isPartnerModal, currentPrivatePersone, currentLegalPersone, isSuccess, isLoading } = useAppSelector(
    (state) => state.partners
  );

  const [patnersList, setPatnersList] = useState<PartnersFromSearch[]>([]);
  const [contactsList, setContactsList] = useState<{ id: number; name: string; email: string; phone: string }[]>(
    preSaveOrder.basicInfo?.contactList || []
  );
  useEffect(() => {
    if (preSaveOrder.basicInfo?.contactList) {
      setContactsList(preSaveOrder.basicInfo?.contactList);
    }
  }, [preSaveOrder.basicInfo?.contactList]);

  const usersData = useAppSelector((state) => state.users.users);
  const { orderActions: orderActionsFromState, params } = useAppSelector((state) => state.orderActions);
  const currentUser = useAppSelector((state) => state.users.currentUser);
  const dispatch = useAppDispatch();
  const [openedPartnerEdit, setOpenedPartnerEdit] = useState<boolean>(false);
  const [openedPartnerCreate, setOpenedPartnerCreate] = useState<boolean>(false);
  const tabsData = getOrderTabs(preSaveOrder);
  const [tab, setTab] = useState<string>(BasicInfoTabs.HISTORY);
  const [partner, setPartner] = useState<{ id: number; name: string } | null>(
    (preSaveOrder.basicInfo?.partner as { id: number; name: string }) || basicInfo?.partner || null
  );
  const [manager, setManager] = useState<{ id: number; name: string } | null>(
    preSaveOrder.basicInfo?.manager ||
      basicInfo?.manager ||
      (currentUser && { id: currentUser?.id, name: `${currentUser?.first_name} ${currentUser?.last_name}` }) ||
      null
  );
  const [statusOrder, setStatusOrder] = useState<string>(
    preSaveOrder.basicInfo?.status || basicInfo?.status || statusesOrder[0]
  );
  const [statusPayment, setStatusPayment] = useState<string>(
    preSaveOrder.basicInfo?.payed_status || basicInfo?.payed_status || statusesPayment[0]
  );
  const [newNote, setNewNote] = useState<BasicInfoNote>(
    preSaveOrder.basicInfo?.newNote || {
      description: '',
      files: [],
    }
  );
  const [reminders, setReminders] = useState<ReminderType[]>(
    preSaveOrder.basicInfo?.reminders || basicInfo?.reminders || []
  );
  const [orderActions, setOrderActions] = useState<OrderAction[]>(
    preSaveOrder.basicInfo?.orderActions?.length ? preSaveOrder.basicInfo?.orderActions : basicInfo?.orderActions || []
  );
  const [allData, setAllData] = useState<BasicInfo>();
  const [isEditPartner, setIsEditPartner] = useState(false);

  // поиск выбранного контрагента
  const selectedPartner:
    | {
        id: number;
        name: string;
        phone?: string;
        email?: string;
        contact?: string;
        birthday?: string | null;
      }
    | undefined = useMemo(() => {
    return patnersList.find((item) => item.id === partner?.id);
  }, [patnersList, partner, isEditPartner]);

  // обегченный массив контрагентов (id+name) для компонента SelectSearch
  const usersDTO = useMemo(() => {
    return (usersData || []).map((item) => {
      if (item.name?.trim()) {
        return { id: item.id || 0, name: item.name };
      } else return { id: item.id || 0, name: 'no name' };
    });
  }, [usersData]);

  //начальные значения для стандартных полей формы
  const currentPartner = useMemo(() => {
    const item = partner && patnersList.find((el) => el.id === partner.id);
    return item;
  }, [patnersList]);

  useEffect(() => {
    setOrderActions(orderActionsFromState);
  }, [orderActionsFromState]);

  useEffect(() => {
    if (isEditPartner) {
      setPartner(
        selectedPartner
          ? selectedPartner
          : currentPartner
          ? currentPartner
          : (basicInfo?.partner as { id: number; name: string })
      );
    }
  }, [isEditPartner, currentPartner, selectedPartner]);

  const initialValues: BasicInfo = useMemo(() => {
    return {
      partner: preSaveOrder.basicInfo?.partner || basicInfo?.partner || partner,
      phone: preSaveOrder.basicInfo?.phone || currentPartner?.phone || basicInfo?.phone || '',
      email: preSaveOrder.basicInfo?.email || currentPartner?.email || basicInfo?.email || '',
      contactPerson: preSaveOrder.basicInfo?.contactPerson || basicInfo?.contactPerson || '',
      manager: manager || null,
      status: statusOrder || '',
      payed_status: statusPayment || '',
      refundAmount: preSaveOrder.basicInfo?.refundAmount || basicInfo?.refundAmount || 0,
      requisites: preSaveOrder.basicInfo?.requisites,
    };
  }, []);
  const formBasicInfo = useForm({
    initialValues,
  });

  // установка данных полей в зависимости от выбора контрагента
  useEffect(() => {
    formBasicInfo.setValues({
      ...formBasicInfo.values,
      ...{ phone: selectedPartner?.phone ? selectedPartner.phone : currentPartner?.phone ? currentPartner?.phone : '' },
      ...{ email: selectedPartner?.email ? selectedPartner.email : currentPartner?.email ? currentPartner?.email : '' },
    });

    setPartner(selectedPartner ? selectedPartner : currentPartner ? currentPartner : partner);
  }, [selectedPartner, currentPartner]);

  //формирование первичного файла с данными (предустановки)
  useEffect(() => {
    setAllData({
      ...formBasicInfo.values,
      newNote: newNote,
      reminders: reminders,
      orderActions: orderActions,
    });
  }, [formBasicInfo.values, newNote, reminders, orderActions]);
  //сбор данных
  useEffect(() => {
    formBasicInfo.setFieldValue('partner', partner);
  }, [partner]);

  useEffect(() => {
    formBasicInfo.setFieldValue('manager', manager);
  }, [manager]);

  useEffect(() => {
    formBasicInfo.setFieldValue('status', statusOrder);
  }, [statusOrder]);

  useEffect(() => {
    formBasicInfo.setFieldValue('payed_status', statusPayment);
  }, [statusPayment]);
  //сбор данных из управляемых полей формы
  useEffect(() => {
    setAllData({ ...allData, ...formBasicInfo.values });
  }, [formBasicInfo.values]);

  //сбор данных из массива напоминаний
  const setRemindersInfo = (reminders: ReminderType[]) => {
    setReminders(reminders);
    setAllData({ ...allData, reminders: reminders });
  };

  //сбор данных из блока с историей
  const setNewNoteData = (note: BasicInfoNote) => {
    setNewNote(note);
    setAllData({ ...allData, newNote: note });
  };

  // сохранения примечание в массив примечаний и обнуление "нового" примечания
  const addNewNote = ({ description: text = '', files: attachments = [] }: BasicInfoNote) => {
    setOrderActions((v) =>
      v.concat([
        {
          id: v.length + 1,
          description: text,
          files: attachments,
          date: new Date(),
          type: OrderActionsStatus.NOTE,
          user: currentUser?.first_name ? currentUser.first_name : '',
        },
      ])
    );

    preSaveOrder &&
      dispatch(addOrderAction({ tour_order: (preSaveOrder ? preSaveOrder['@id'] : '') as string, text, attachments }));
  };

  //предсохранение всех данных в стейте и сбор данных всей вкладки для отправки в родительский компонент
  useEffect(() => {
    if (allData) {
      dispatch(setPreSaveOrder({ ...preSaveOrder, basicInfo: allData }));
    }
  }, [allData]);

  //сброс всех предварительно сохраненных данных в полях формы. работает когда закрываем модалку.
  //для остальных полей работает стандартный сброс через редакс
  useEffect(() => {
    if (isResetPreSaveOrder) {
      formBasicInfo.reset();
      formBasicInfo.setFieldValue('phone', initialValues.phone);
      formBasicInfo.setFieldValue('email', initialValues.email);
      formBasicInfo.setFieldValue('contactPerson', initialValues.contactPerson);
      formBasicInfo.setFieldValue('email', initialValues.email);
    }
  }, [isResetPreSaveOrder]);

  //обработка клика на конкретного контрагента
  const handlePartnerClick = (partnerId: number) => {
    setOpenedPartnerEdit(true);
    setOpenedPartnerCreate(false);
    dispatch(setIsPartnerModal(true));
    dispatch(getPartner({ id: partnerId.toString() }));
  };

  //обработка клика на "создание контрагента"
  const handlePartnerCreateClick = () => {
    dispatch(resetPreSavePrivatePerson());
    dispatch(resetPreSaveLegalPerson());
    setOpenedPartnerEdit(false);
    setOpenedPartnerCreate(true);
    dispatch(setIsPartnerModal(true));
  };
  const [searchVal, setSearchVal] = useState('');

  useEffect(() => {
    searchPartnersUpgrate(patnersList, searchVal, setPatnersList);
  }, [patnersList, searchVal]);

  const loadPartners = async () => {
    await partnersAPI({
      search: searchVal,
      properties: propers,
      itemsPerPage: 100,
    }).then((res) => {
      const data = res.data['hydra:member'].map((partner) => {
        return getPartnerObj(partner);
      });
      setPatnersList(data);
    });
  };

  const handleKey = async () => {
    if (searchVal != '') {
      loadPartners();
      return;
    }
  };

  useEffect(() => {
    if (isSuccess === 'EDIT_PARTNER') {
      setIsEditPartner(true);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isEditPartner) {
      loadPartners();
      return;
    }
  }, [isEditPartner]);

  useEffect(() => {
    if (isEditPartner) {
      setIsEditPartner(false);
    }
  }, [isEditPartner]);

  useEffect(() => {
    if (params.isSystemActions !== null && preSaveOrder['@id'] && params.hiddenAllActions === false) {
      dispatch(
        getOrderActions({ tour_order: parseInt(preSaveOrder?.id as string), is_system: params.isSystemActions })
      );
    }
    if (params.isSystemActions === null && preSaveOrder['@id'] && params.hiddenAllActions === false) {
      dispatch(getOrderActions({ tour_order: parseInt(preSaveOrder?.id as string) }));
    }
  }, [params.isSystemActions, params.hiddenAllActions]);

  useEffect(() => {
    if (!preSaveOrder.id) {
      if (selectedPartner?.contact != null) {
        dispatch(
          setPreSaveOrder({
            ...preSaveOrder,
            documentsAndPayment: preSaveOrder.documentsAndPayment && {
              ...preSaveOrder.documentsAndPayment,
              payer: selectedPartner && {
                id: selectedPartner.id,
                name: selectedPartner.name,
                email: selectedPartner.email,
              },
            },
          })
        );
      } else {
        dispatch(
          setPreSaveOrder({
            ...preSaveOrder,
            tourInfo: preSaveOrder.tourInfo && {
              ...preSaveOrder.tourInfo,
              tourists:
                preSaveOrder.tourInfo &&
                preSaveOrder.tourInfo.tourists.concat(
                  selectedPartner && !preSaveOrder.tourInfo.tourists.find((el) => el.name === selectedPartner.name)
                    ? [
                        {
                          id: selectedPartner?.id,
                          name: selectedPartner.birthday
                            ? `${selectedPartner?.name} / ${getDate(new Date(selectedPartner?.birthday))}`
                            : selectedPartner?.name,
                        },
                      ]
                    : []
                ),
            },
            documentsAndPayment: preSaveOrder.documentsAndPayment && {
              ...preSaveOrder.documentsAndPayment,
              payer: selectedPartner && {
                id: selectedPartner.id,
                name: selectedPartner.name,
                email: selectedPartner.email,
              },
            },
          })
        );
      }
    }
  }, [partner]);

  return (
    <>
      <section className={styles.form}>
        <section className={styles.contacts}>
          <SelectSearch
            data={patnersList}
            label="Контрагент"
            value={partner}
            onBlur={setDirty}
            setValue={(a) => {
              setPartner(a);
              const find = patnersList.find((el) => el.id === a.id);
              if (find) {
                setContactsList(find.contact_array);
                formBasicInfo.setFieldValue('contactPerson', '');
                setPreSaveOrder({
                  ...preSaveOrder,
                  basicInfo: preSaveOrder.basicInfo && {
                    ...preSaveOrder.basicInfo,
                    contactPerson: '',
                  },
                });
              }
            }}
            setSearch={setSearchVal}
            handleKey={handleKey}
            getValueForModal={handlePartnerClick}
            create={handlePartnerCreateClick}
            placeholder="Не выбран"
            className={styles.partner}
            isNeedSort
            disabled={!isCanEdit}
          />
          <SelectSearch
            data={contactsList}
            label="Контактное лицо"
            value={formBasicInfo.values.contactPerson ? { id: 1, name: formBasicInfo.values.contactPerson } : null}
            onBlur={setDirty}
            setValue={(a) => {
              formBasicInfo.setFieldValue('contactPerson', a.name);
              formBasicInfo.setFieldValue('email', a.email);
              formBasicInfo.setFieldValue('phone', a.phone);
              console.log(a);
              setPreSaveOrder({
                ...preSaveOrder,
                basicInfo: preSaveOrder.basicInfo && {
                  ...preSaveOrder.basicInfo,
                  contactPerson: a.name,
                  email: String(a.email),
                  phone: String(a.phone),
                },
              });
            }}
            className={styles.partner}
            isNeedSort
            disabled={!isCanEdit || contactsList.length === 0}
          />
          <Input
            label="Телефон"
            {...formBasicInfo.getInputProps('phone')}
            disabled
            className={styles.phone}
            iconLeft={<PhoneIcon fill={'#C8CDD8'} width={14} height={14} />}
          />
          <Input
            label="Почта"
            {...formBasicInfo.getInputProps('email')}
            disabled
            className={styles.email}
            iconLeft={<MailIcon fill={'#C8CDD8'} width={14} height={14} />}
          />
          <SelectNew
            selected={manager}
            onBlur={setDirty}
            data={usersDTO}
            setSelect={(e) => {
              const { id = 0, ...data } = e;
              setManager({ id, ...data });
            }}
            label="Менеджер"
            className={styles.partner}
            disabled={!isCanEdit}
          />
          <Select
            onBlur={setDirty}
            selected={statusOrder}
            data={statusesOrder}
            setSelect={setStatusOrder}
            label="Статус заказа"
            className={styles.status}
          />
          <Select
            onBlur={setDirty}
            selected={statusPayment}
            data={statusesPayment}
            setSelect={setStatusPayment}
            label="Статус оплаты"
            className={styles.status}
          />
          {statusOrder === OrderStatus.CANCELLED && (
            <Input
              label="Сумма на возврат"
              placeholder="Введите сумму возврата"
              {...formBasicInfo.getInputProps('refundAmount')}
              className={styles.refundAmount}
              onBlur={setDirty}
              type="number"
            />
          )}
        </section>
        <section className={styles.history}>
          <div className={styles.historyTop}>
            <User id={Number(manager?.id)} name={String(manager?.name)} viewName={false} className={styles.user} />
            <TabsPanel
              tab={tab}
              tabs={tabsData}
              color={'#F7A860'}
              setActive={setTab}
              className={styles.tabs}
              specialTab={{
                tabName: BasicInfoTabs.CHAT,
                count: preSaveOrder.countUnread ? preSaveOrder.countUnread : 0,
              }}
            />
          </div>

          {tab === BasicInfoTabs.HISTORY && (
            <div className={styles.editor}>
              <TextEditorOrder
                data={newNote}
                setData={setNewNoteData}
                addNewNote={addNewNote}
                placeholder="Добавьте примечание"
                isAddButtonActive={preSaveOrder?.['@id'] ? true : false}
                isCanEdit={!isCanEdit || !preSaveOrder.id}
              />
              <Reminders reminders={reminders} setReminders={setRemindersInfo} isCanEdit={!isCanEdit} />
              <OrderActions orderActions={orderActions} />
            </div>
          )}
          {tab === BasicInfoTabs.REQUEST && preSaveOrder.site_request && (
            <RequestFromSite request={preSaveOrder.site_request} />
          )}
          {tab === BasicInfoTabs.CHAT && <Chat />}
        </section>
      </section>

      {openedPartnerEdit && isPartnerModal && (
        <Modal opened={isPartnerModal} setOpened={setOpenedPartnerEdit}>
          {isLoading === 'GET_PARTNER' && (
            <Loader color={'#70a4cb'} variant={'oval'} className={styles.loaderPartner} size={'lg'} />
          )}
          {currentPrivatePersone && <PartnerEdit partner={currentPrivatePersone as PrivatePerson} />}
          {currentLegalPersone && <PartnerEdit partner={currentLegalPersone as LegalPerson} />}
        </Modal>
      )}

      {openedPartnerCreate && isPartnerModal && (
        <Modal opened={isPartnerModal} setOpened={setOpenedPartnerCreate}>
          <PartnerCreate />
        </Modal>
      )}
    </>
  );
};

export default BasicInfoTab;
