import React, { FC, useEffect, useRef, useState } from 'react';
import { RemindersProps, ReminderType } from './Reminders.type';
import { Reminder } from './Reminder';
import { DateTimePicker } from '@mantine/dates';
import { ReminderIcon } from 'components/icons/ReminderIcon';
import { generateId } from 'core/utils/generateId';

import styles from './Reminders.module.scss';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import {
  addOrderNotification,
  removeOrderNotification,
  updateOrderNotification,
} from '../../store/action-creators/orderNotification';
import { getTimezonelessDateReminder } from '../../core/utils/getDate';
import { OrderNotificationData } from '../../services/orderNotification/OrderNotification.types';
import clsx from 'clsx';

export const Reminders: FC<RemindersProps> = ({ reminders, setReminders, isCanEdit = false }) => {
  const { preSaveOrder } = useAppSelector((state) => state.orders);
  const dispatch = useAppDispatch();

  const [labelNewReminder, setLabelNewReminder] = useState('');
  const [dateNewReminder, setDateNewReminder] = useState<Date | null>(null);
  const [newReminder, setNewReminder] = useState<ReminderType>();
  const [remindersList, setRemindersList] = useState(reminders);
  const [viewNewDate, setViewNewDate] = useState(false);
  const addNewReminder = (e: React.KeyboardEvent) => {
    if (e.key == 'Enter') {
      e.preventDefault();
      if (newReminder && labelNewReminder != '') {
        newReminder.id = generateId();
        setRemindersList([...remindersList, newReminder]);
        setLabelNewReminder('');
        setDateNewReminder(null);
        setViewNewDate(false);
      }
    }
  };

  const listRef = useRef<ReminderType[]>();
  useEffect(() => {
    if (remindersList && listRef.current && JSON.stringify(listRef.current) !== JSON.stringify(remindersList)) {
      processReminders(remindersList);
    }
    listRef.current = remindersList;
  }, [remindersList]);

  const processReminders = (data: ReminderType[]) => {
    if (!preSaveOrder || !preSaveOrder['@id']) return;
    data.map((i: ReminderType) => {
      const data = {
        id: i['@id'] ? i.id : undefined,
        text: i.label,
        //notify_at: i.date ? getTimezonelessDateReminder(i.date) : undefined,
        notify_at: i.date ? (i.date) : undefined,
        is_completed: i.status,
        tour_order: preSaveOrder['@id'],
      } as unknown as OrderNotificationData;

      if (i['@id']) {
        dispatch(updateOrderNotification(data));
      } else {
        dispatch(addOrderNotification(data));
      }
    });
  };

  const deleteRemoteReminder = (id?: string) => {
    if (!id) return;

    dispatch(removeOrderNotification({ id }));
  };

  const deleteReminder = (e: React.MouseEvent, id: number) => {
    e.preventDefault();
    setRemindersList(remindersList.filter((reminder) => reminder.id !== id));
  };

  const checkedReminder = (e: React.MouseEvent, id: number) => {
    const updateReminders = remindersList.map((item) => {
      if (item.id === id) {
        item = { ...item, status: !item.status };
      }
      return item;
    });
    setRemindersList(updateReminders);
  };

  const handleSetDate = (e: React.MouseEvent) => {
    e.preventDefault();
    !isCanEdit && setViewNewDate((prev) => !prev);
  };

  useEffect(() => {
    setNewReminder({
      id: 0,
      label: labelNewReminder,
      status: false,
      date: dateNewReminder ? dateNewReminder : null,
    });
  }, [labelNewReminder, dateNewReminder]);

  useEffect(() => {
    if (setReminders && remindersList) {
      setReminders(remindersList);
    }
  }, [remindersList]);

  return (
    <section className={styles.reminders}>
      <h4 className={styles.title}>Напоминания</h4>
      {remindersList.map((reminder) => (
        <Reminder
          reminder={reminder}
          key={reminder.id}
          deleteId={deleteReminder}
          deleteRemoteId={deleteRemoteReminder}
          checkedId={checkedReminder}
        />
      ))}
      <div onKeyDown={addNewReminder} className={clsx(styles.addNewReminder, isCanEdit && styles.disabled)}>
        <input
          type="text"
          value={labelNewReminder}
          onChange={(e) => setLabelNewReminder(e.target.value)}
          placeholder="Добавить напоминание"
          className={styles.labelNewReminder}
          disabled={isCanEdit}
        />

        {viewNewDate && (
          <DateTimePicker
            valueFormat="DD.MM.YYYY hh:mm"
            value={dateNewReminder}
            onChange={setDateNewReminder}
            className={styles.datePicker}
            placeholder="_ _ . _ _ . _ _ _ _ "
            miw={dateNewReminder === null ? 100 : 160}
            styles={{ input: { backgroundColor: '#F5F6FA' } }}
            clearable
          />
        )}
        {!viewNewDate && (
          <button onClick={handleSetDate} className={styles.btnNewReminder}>
            <ReminderIcon width={14} height={14} fill="#C8CDD8" />
          </button>
        )}
      </div>
    </section>
  );
};
