import React, { FC, useEffect, useState } from 'react';
import { useForm } from '@mantine/form';
import clsx from 'clsx';
import { CommonTabProps } from './CommonTab.types';
import { CommonInfoLegalPerson } from '../PartnerFormLegalPerson.types';
import { Input } from 'ui/Input';
import { PhoneIcon, MailIcon } from 'components/icons';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setPreSaveLegalPerson } from 'store/entities/partners/partnersSlice';
import upload from '../../../../../assets/icons/upload.svg';
import styles from '../PartnerFormLegalPerson.module.scss';
import { addAgrementDocument, addDocument, editPartner } from 'store/action-creators/partners';
import ImageFile from 'components/TextEditorOrder/ImageFile';
import { srcToFile } from 'services/orders/orders.adapter';
import { Button } from 'ui/Button';
import { Select } from '@mantine/core';
import { addDocumentAPI } from 'services/partners/partnerApi.service';

export const CommonTab: FC<CommonTabProps> = ({ commonInfo, setCommonInfo, markDirty, setErrorList }) => {
  const { preSaveLegalPerson, isResetPreSaveLegalPerson } = useAppSelector((state) => state.partners);
  const dispatch = useAppDispatch();
  const [blur, setBlur] = useState(false);
  const [focus, setFocus] = useState(false);
  const [agrFiles, setAgrFiles] = useState<File[]>([]);

  const initialValues: CommonInfoLegalPerson = {
    name: preSaveLegalPerson?.commonInfo.name || commonInfo?.name || '',
    company_email: preSaveLegalPerson.commonInfo.company_email || commonInfo?.company_email || '',
    cooperation_format: preSaveLegalPerson.commonInfo.cooperation_format || commonInfo?.cooperation_format || '',
    contactPerson: {
      name: preSaveLegalPerson?.commonInfo.contactPerson.name || commonInfo?.contactPerson.name || '',
      phone: preSaveLegalPerson?.commonInfo.contactPerson.phone || commonInfo?.contactPerson.phone || '',
      email: preSaveLegalPerson?.commonInfo.contactPerson.email || commonInfo?.contactPerson.email || '',
    },
    contacts_array: preSaveLegalPerson.commonInfo.contacts_array || commonInfo?.contacts_array || [],
    commission: preSaveLegalPerson.commonInfo.commission || null,
    comments: preSaveLegalPerson?.commonInfo.comments || commonInfo?.comments || '',
    register_number: preSaveLegalPerson.commonInfo.register_number || commonInfo?.register_number || '',
    register_agent_number:
      preSaveLegalPerson.commonInfo.register_agent_number || commonInfo?.register_agent_number || '',
    document_template_path: preSaveLegalPerson.commonInfo.document_template_path || '',
    agreement_path: preSaveLegalPerson.commonInfo.agreement_path || [],
    file: preSaveLegalPerson.commonInfo.file || undefined,
  };

  const validate = {
    name: (value: string) => (value.length == 0 ? 'the field is required' : null),
    contactPerson: {
      name: (value: string) => (value.length == 0 ? 'the field is required' : null),
      phone: (value: string) => (value.length == 0 ? 'the field is required' : null),
    },
  };
  useEffect(() => {
    const load = async () => {
      const res = await Promise.all(
        [preSaveLegalPerson.commonInfo.document_template_path].map(async (i: string) => {
          return await srcToFile(`${process.env.REACT_APP_BASE_API_URL}${i}`, i.substring(i.lastIndexOf('/') + 1));
        })
      );
      formCommonInfo.setFieldValue('document_template_path', preSaveLegalPerson.commonInfo.document_template_path);
      formCommonInfo.setFieldValue('file', res);
    };
    if (preSaveLegalPerson.commonInfo.document_template_path) {
      load();
    }
  }, [preSaveLegalPerson.commonInfo.document_template_path]);

  useEffect(() => {
    const load = async () => {
      const res = await Promise.all(
        preSaveLegalPerson.commonInfo.agreement_path.map(async (i: string) => {
          return await srcToFile(`${process.env.REACT_APP_BASE_API_URL}${i}`, i.substring(i.lastIndexOf('/') + 1));
        })
      );
      setAgrFiles(res);
    };
    if ((agrFiles.length === 0) && (preSaveLegalPerson.commonInfo.agreement_path) && (preSaveLegalPerson.commonInfo.agreement_path.length > 0)){
      load();
    }
  }, [agrFiles]);

  const formCommonInfo = useForm({
    initialValues,
    validate,
    validateInputOnChange: true,
  });

  useEffect(() => {
    setCommonInfo(formCommonInfo.values);
  }, [formCommonInfo.values]);

  useEffect(() => {
    if (focus && Object.values(formCommonInfo.errors).length === 0) {
      setFocus(false);
      markDirty();
    }
  }, [formCommonInfo.errors, focus]);

  useEffect(() => {
    setErrorList(Object.keys(formCommonInfo.errors));
  }, [formCommonInfo.errors]);

  //если форму начали заполнять и в ней нет ошибок, данные уходят в предсохранении и оттуда на сервер
  useEffect(() => {
    if (formCommonInfo.values.name !== '' && JSON.stringify(formCommonInfo.errors) === '{}') {
      dispatch(setPreSaveLegalPerson({ ...preSaveLegalPerson, commonInfo: formCommonInfo.values }));
    }
  }, [formCommonInfo.values, formCommonInfo.errors]);

  //если первое поле начали заполнять - то все остальные уже обязательны
  useEffect(() => {
    if (blur) {
      formCommonInfo.validate();
    }
  }, [blur]);

  useEffect(() => {
    if (isResetPreSaveLegalPerson) {
      formCommonInfo.reset();
      formCommonInfo.setFieldValue('name', initialValues.name);
      formCommonInfo.setFieldValue('email', initialValues.company_email);
      formCommonInfo.setFieldValue('contactPerson.name', initialValues.contactPerson.name);
      formCommonInfo.setFieldValue('contactPerson.phone', initialValues.contactPerson.phone);
      formCommonInfo.setFieldValue('contactPerson.email', initialValues.contactPerson.email);
      formCommonInfo.setFieldValue('comments', initialValues.comments);
      formCommonInfo.setFieldValue('register_number', initialValues.register_number);
      formCommonInfo.setFieldValue('file', initialValues.file);
      formCommonInfo.setFieldValue('document_template_path', initialValues.document_template_path);
      formCommonInfo.setFieldValue('commission', initialValues.commission);
    }
  }, [isResetPreSaveLegalPerson]);

  const handleAddFiles = (e: React.ChangeEvent<HTMLInputElement>) => {
    const addedFiles = e.target.files as FileList;
    const filesFromInput = Array.from(addedFiles).slice(0, 1);
    formCommonInfo.setFieldValue('file', filesFromInput);
    dispatch(addDocument({ file: filesFromInput }));
    e.target.value = '';
  };

  const handleAddAgrementsFiles = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const addedFiles = e.target.files as FileList;
    const filesFromInput = Array.from(addedFiles).slice(0, 2);
    setAgrFiles((prev) => {
      return [...filesFromInput, ...prev];
    });
    const res = filesFromInput.map(async (el) => {
      const response = await addDocumentAPI({ file: el });
      return response.data.path;
    });
    const data = await Promise.all(res);
    dispatch(
      setPreSaveLegalPerson({
        ...preSaveLegalPerson,
        commonInfo: {
          ...formCommonInfo.values,
          agreement_path: [...data, ...preSaveLegalPerson.commonInfo.agreement_path],
        },
      })
    );
    dispatch(
      editPartner({
        id: preSaveLegalPerson.id?.toString() as string,
        data: { agreement_path: [...data, ...preSaveLegalPerson.commonInfo.agreement_path] },
      })
    );
    e.target.value = '';
  };

  console.log(agrFiles);
  return (
    <form className={styles.commonForm}>
      <div className={styles.group}>
        <Input
          label="Фирменное наименование компании*"
          {...formCommonInfo.getInputProps('name')}
          className={styles.input}
          onBlur={() => {
            setBlur(true);
            setFocus(true);
          }}
          error={!!formCommonInfo.errors.name}
        />
        <Input
          label="Почта компании"
          {...formCommonInfo.getInputProps('company_email')}
          className={styles.input}
          onBlur={() => {
            setBlur(true);
            setFocus(true);
          }}
          error={!!formCommonInfo.errors.company_email}
        />
        <Input
          label="Повышенный % комиссии"
          {...formCommonInfo.getInputProps('commission')}
          className={styles.input}
          onChange={(e: { target: { value: string } }) => {
            if (Number(e.target.value) > 100) return;
            if (e.target.value.match(/^\d+$/)) {
              formCommonInfo.setFieldValue('commission', e.target.value);
            } else {
              formCommonInfo.setFieldValue(
                'commission',
                e.target.value === '' ? e.target.value : formCommonInfo.values.commission
              );
            }
          }}
          onBlur={setFocus}
        />
      </div>
      <div className={styles.group}>
        <Input
          label="Контактное лицо 1"
          {...formCommonInfo.getInputProps('contactPerson.name')}
          className={styles.input}
          onBlur={setFocus}
          error={!!formCommonInfo.errors['contactPerson.name']}
        />
        <Input
          type="text"
          label="Телефон контактного лица*"
          {...formCommonInfo.getInputProps('contactPerson.phone')}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            if (e.target.value.match(/^\d+$/)) {
              formCommonInfo.setFieldValue('contactPerson.phone', e.target.value);
            } else {
              formCommonInfo.setFieldValue(
                'contactPerson.phone',
                e.target.value === '' ? e.target.value : formCommonInfo.values.contactPerson.phone
              );
            }
          }}
          className={clsx(styles.phone, styles.number)}
          onBlur={setFocus}
          iconLeft={<PhoneIcon fill={'#C8CDD8'} width={14} height={14} />}
          error={!!formCommonInfo.errors['contactPerson.phone']}
          onWheel={(e: any) => e.target.blur()}
        />
        <Input
          label="Почта контактного лица"
          {...formCommonInfo.getInputProps('contactPerson.email')}
          className={styles.email}
          onBlur={setFocus}
          iconLeft={<MailIcon fill={'#C8CDD8'} width={14} height={14} />}
        />
      </div>
      {formCommonInfo.values.contacts_array.map((el, idx) => {
        return (
          <div className={styles.group} key={idx}>
            <Input
              label={`Контактное лицо ${idx === 0 ? 2 : idx + 2}`}
              {...formCommonInfo.getInputProps(`contacts_array.${idx}.name`)}
              className={styles.input}
              onBlur={setFocus}
            />
            <Input
              type="text"
              label="Телефон контактного лица*"
              {...formCommonInfo.getInputProps(`contacts_array.${idx}.phone`)}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                if (e.target.value.match(/^\d+$/)) {
                  formCommonInfo.setFieldValue(`contacts_array.${idx}.phone`, e.target.value);
                } else {
                  formCommonInfo.setFieldValue(
                    `contacts_array.${idx}.phone`,
                    e.target.value === '' ? e.target.value : formCommonInfo.values.contactPerson.phone
                  );
                }
              }}
              className={clsx(styles.phone, styles.number)}
              onBlur={setFocus}
              iconLeft={<PhoneIcon fill={'#C8CDD8'} width={14} height={14} />}
              onWheel={(e: any) => e.target.blur()}
            />
            <Input
              label="Почта контактного лица"
              {...formCommonInfo.getInputProps(`contacts_array.${idx}.email`)}
              className={styles.email}
              onBlur={setFocus}
              iconLeft={<MailIcon fill={'#C8CDD8'} width={14} height={14} />}
            />
          </div>
        );
      })}
      <Button
        transparent={true}
        label="+ Добавить контактное лицо"
        className={styles.addRow}
        onClick={() =>
          formCommonInfo.insertListItem('contacts_array', {
            name: '',

            email: '',
            phone: '',
          })
        }
      />
      <Select
        {...formCommonInfo.getInputProps('cooperation_format')}
        data={['Оба варианта', 'Агентский договор', 'Договор нетто']}
        label="Формат сотрудничества"
        selected={formCommonInfo.values.cooperation_format}
        onBlur={() => {
          setBlur(true);
          setFocus(true);
        }}
        rightSectionWidth={30}
        styles={{
          rightSection: { pointerEvents: 'none', display: 'none' },
          label: {
            marginBottom: '10px',
          },
          input: {
            border: '1px solid rgba(200, 205, 216, 0.6) !important',
            fontFamily: 'Museo Sans Cyrl',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '15px',
            lineHeight: '19px',
            color: '#263B53',
            padding: '20px 16px',
          },
          item: {
            fontFamily: 'Museo Sans Cyrl',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '15px',
            lineHeight: '19px',
            color: '#263B53 !important',
            background: 'none !important',
          },
          dropdown: {
            border: '1px solid rgba(200, 205, 216, 0.6) !important',
            // boxShadow: 'none !important',
          },
        }}
      />
      <div className={styles.textarea}>
        <label className={styles.textareaLabel}>Примечание</label>
        <textarea
          placeholder="Введите примечание"
          {...formCommonInfo.getInputProps('comments')}
          className={styles.textareaText}
          onBlur={setFocus}
        />
      </div>
      <div className={styles.dobbleGroup}>
        <Input
          label="Реестровый номер туроператора"
          {...formCommonInfo.getInputProps('register_number')}
          className={styles.input}
          onBlur={() => {
            setBlur(true);
            setFocus(true);
          }}
          error={!!formCommonInfo.errors.register_number}
        />
        <Input
          label="Реестровый номер агента"
          {...formCommonInfo.getInputProps('register_agent_number')}
          className={styles.input}
          onBlur={() => {
            setBlur(true);
            setFocus(true);
          }}
          error={!!formCommonInfo.errors.register_agent_number}
        />
      </div>
      <div className={styles.applicationBlock}>
        <h2>Договор</h2>
        <div className={styles.addFileBlock}>
          <span>Загрузить :</span>
          <label htmlFor="arg" className={styles.addFileLabel}>
            <img src={upload} width={14} height={14} /> Нажмите, чтобы загрузить
          </label>
          <input
            type="file"
            id="arg"
            multiple
            name="arg"
            onChange={(e) => handleAddAgrementsFiles(e)}
            className={styles.addFile}
          />
        </div>
        {agrFiles.map((file: File, idx) => (
          <ImageFile
            key={idx}
            preview={URL.createObjectURL(file as Blob)}
            name={file.name}
            size={file.size}
            canDownload
            type={file.type}
            deleteFile={() => {
              const arr = agrFiles.filter((_, id) => id !== idx);
              setAgrFiles(arr);
              dispatch(
                setPreSaveLegalPerson({
                  ...preSaveLegalPerson,
                  commonInfo: {
                    ...formCommonInfo.values,
                    agreement_path: preSaveLegalPerson.commonInfo.agreement_path.filter(
                      (_: any, id: number) => id !== idx
                    ),
                  },
                })
              );
              dispatch(
                editPartner({
                  id: preSaveLegalPerson.id?.toString() as string,
                  data: {
                    agreement_path: preSaveLegalPerson.commonInfo.agreement_path.filter(
                      (_: any, id: number) => id !== idx
                    ),
                  },
                })
              );
            }}
          />
        ))}
      </div>
      <div className={styles.applicationBlock}>
        <h2>Приложение №2 (фин.обеспечение)</h2>
        <div className={styles.addFileBlock}>
          <span>Загрузить :</span>
          <label htmlFor="file" className={styles.addFileLabel}>
            <img src={upload} width={14} height={14} /> Нажмите, чтобы загрузить
          </label>
          <input
            type="file"
            id="file"
            name="file"
            accept=".doc,.docx"
            onChange={(e) => handleAddFiles(e)}
            className={styles.addFile}
          />
        </div>
        {formCommonInfo.values.file &&
          formCommonInfo.values.file.map((file: File) => (
            <ImageFile
              key={file.name}
              preview={URL.createObjectURL(file as Blob)}
              name={file.name}
              size={file.size}
              canDownload
              type={file.type}
              deleteFile={() => {
                formCommonInfo.setFieldValue('document_template_path', '');
                formCommonInfo.setFieldValue('file', undefined);
              }}
            />
          ))}
      </div>
    </form>
  );
};
