import React from 'react';
import { RouteNames } from 'core/constants/routeNames';
import { NavigationType } from './LayoutNavbar.type';
import { OrdersIcon, PartnersIcon, ReportsIcon, ToursIcon } from 'components/icons';
import { GalleryIcon } from 'components/icons/GalleryIcon';

export const navigation: NavigationType[] = [
  {
    icon: <OrdersIcon width={22} height={22} />,
    title: 'Заказы',
    href: RouteNames.ORDERS,
  },
  {
    icon: <ToursIcon width={22} height={22} />,
    title: 'Туры',
    href: RouteNames.TOURS,
  },
  {
    icon: <PartnersIcon width={22} height={22} />,
    title: 'Контрагенты',
    href: RouteNames.PARTNERS,
  },
  {
    icon: <GalleryIcon width={22} height={22} />,
    title: 'Галерея',
    href: RouteNames.GALLERY,
  },
  {
    icon: <ReportsIcon width={22} height={22} />,
    title: 'Отчеты',
    href: RouteNames.REPORTS,
  },
];
