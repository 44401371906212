import { PartnerShortInfo } from 'pages/Partners/PartnersTable/PartnersTable.types';
import { OrderShortResponse, PartnerResponse, PartnerShortResponse } from './PartnerResponse.model';
import {
  OrdersHistoryItem,
  PrivatePerson,
} from 'pages/Partners/components/PartnerFormPrivatePerson/PartnerFormPrivatePerson.types';
import { StatusPartner } from 'core/constants/enums';
import { LegalPerson } from 'pages/Partners/components/PartnerFormLegalPerson/PartnerFormLegalPerson.types';
import { getStatus, getStatusLK } from 'core/utils/getStatus';
import { srcToFile } from 'services/orders/orders.adapter';

export const partnersFactory = (model: PartnerShortResponse): PartnerShortInfo => {
  const type = model.type.map((item) => item.name);

  return {
    id: model.id,
    name: model.full_name,
    type: type.join(', '),
    phone: model.phone,
    email: model.email,
    comment: model.note,
    contact: model.contact_full_name,
    firstName: model.first_name,
    secondName: model.second_name,
    middleName: model.middle_name,
    company_email: model.company_email,
    registration_status: model.registration_status,
    requisites:
      model.requisites && model.requisites.length > 0
        ? model.requisites.map((el) => {
            return {
              id: el['@id'],
              legalName: el.juri_name,
            };
          })
        : [],
  };
};

export const historyFactory = (model: OrderShortResponse): OrdersHistoryItem => {
  return {
    id: model.id,
    date: new Date(model.created_at),
    tour: model.tour === null ? '' : model.tour.name,
    status: model.status === null ? '' : getStatus(model.status.value),
  };
};

export const partnerPrivateFactory = (model: PartnerResponse): PrivatePerson => {
  const orders = model.orders.map((order) => historyFactory(order));

  return {
    id: model.id,
    status: StatusPartner.PRIVATE_PERSON,
    type: model.type,
    commonInfo: {
      lastName: model.second_name,
      firstName: model.first_name,
      patronymic: model.middle_name,
      gender: model.gender,
      birthDate: model.birthday === null ? null : new Date(model.birthday),
      phone: model.phone,
      email: model.email,
      comments: model.note,
    },
    documents: {
      mainDocument: model.main_document,
      passport:
        model.contact_passport !== null
          ? {
              id: model?.contact_passport?.['@id'],
              number: model?.contact_passport?.code,
              dateOfIssue: model?.contact_passport?.registered_date
                ? new Date(model?.contact_passport?.registered_date)
                : null,
              validUntil: model?.contact_passport?.actual_before
                ? new Date(model?.contact_passport?.actual_before)
                : null,
              divisionCode: model?.contact_passport?.unit_code,
              issuedByWhom: model?.contact_passport?.unit_name,
              placeOfBirth: model?.contact_passport?.birth_place,
              placeOfStateRegistration: model?.contact_passport?.registration_place,
              countryRegistration: model.contact_passport.registration_country,
            }
          : null,

      interPassport:
        model.contact_foreign_passport !== null
          ? {
              id: model?.contact_foreign_passport?.['@id'],
              lastName: model?.contact_foreign_passport?.last_name,
              firstName: model?.contact_foreign_passport?.first_name,
              number: model?.contact_foreign_passport?.code,
              dateOfIssue: model?.contact_foreign_passport?.registered_date
                ? new Date(model?.contact_foreign_passport?.registered_date)
                : null,
              validUntil: model?.contact_foreign_passport?.actual_before
                ? new Date(model?.contact_foreign_passport?.actual_before)
                : null,
              divisionCode: model?.contact_foreign_passport?.unit_code,
              issuedByWhom: model?.contact_foreign_passport?.unit_name,
              placeOfBirth: model?.contact_foreign_passport?.birth_place,
              placeOfStateRegistration: model?.contact_foreign_passport?.registration_place,
              countryRegistration: model.contact_foreign_passport.registration_country,
            }
          : null,
      birthCertificate:
        model.contact_birth_certificate !== null
          ? {
              id: model?.contact_birth_certificate?.['@id'],
              number: model?.contact_birth_certificate?.code,
              dateOfIssue: model?.contact_birth_certificate?.registered_date
                ? new Date(model?.contact_birth_certificate?.registered_date)
                : null,
              father: model?.contact_birth_certificate?.father_full_name,
              mother: model?.contact_birth_certificate?.mother_full_name,
              placeOfStateRegistration: model?.contact_birth_certificate?.registration_place,
            }
          : null,

      foreignDocument:
        model.contact_foreign_document !== null
          ? {
              id: model?.contact_foreign_document?.['@id'],
              lastName: model.contact_foreign_document?.last_name,
              firstName: model.contact_foreign_document?.first_name,
              citizenship: model.contact_foreign_document?.nationality,
              number: model.contact_foreign_document?.code,
              dateOfIssue: model.contact_foreign_document?.registered_date
                ? new Date(model.contact_foreign_document?.registered_date)
                : null,
              placeOfStateRegistration: model.contact_foreign_document?.registration_place,
              countryRegistration: model.contact_foreign_document?.registration_country,
            }
          : null,
    },
    history: orders,
  };
};

export const partnerLegalFactory = (model: PartnerResponse): LegalPerson => {
  const orders = model.orders.map((order) => historyFactory(order));
  return {
    id: model.id,
    status: StatusPartner.LEGAL_PERSON,
    registration_status: getStatusLK(model.registration_status),
    type: model.type,
    commonInfo: {
      cooperation_format: model.cooperation_format,
      company_email: model.company_email,
      commission: Number(model.commission) && Number(model.commission) > 0 ? Number(model.commission).toString() : '',
      contacts_array: model.contacts_array,
      name: model.company,
      contactPerson: {
        name: model.contact_full_name,
        phone: model.phone,
        email: model.email,
      },
      register_number: model.register_number,
      register_agent_number: model.register_agent_number,
      document_template_path: model.document_template_path,
      agreement_path: model.agreement_path,
      file: undefined,
      comments: model.note,
    },
    requisites:
      model.requisites.length > 0
        ? model.requisites.map((el) => {
            return {
              id: el['@id'],
              legalName: el.juri_name,
              inn: el.inn,
              kpp: el.kpp,
              ogrn: el.ogrn,
              bik: el.bik,
              correspondentAccount: el.corr_account,
              settlementAccount: el.pay_account,
              contract: el.contract,
              address: {
                legal: el.juri_address,
                real: el.address,
              },
              position: el.position,
              responsiblePerson: {
                name: el.manager_full_name,
                permission: el.registration_document,
              },
            };
          })
        : [],

    history: orders,
  };
};
